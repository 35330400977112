/* table */
&gate- {
  &head {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80rem;
    padding: 0 40rem;
    .sel-lang {
      user-select: none;
      display: inline-flex;
      align-items: center;
      position: relative;
      margin-right: auto;
      z-index: 1;
      cursor: pointer;
      .active-lang {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 75rem;
        height: 32rem;
        padding: 0 36rem 0 11rem;
        border: 1rem solid $color-black;
        border-radius: 16rem;
        font-size: 14rem;
        line-height: 16rem;
        font-weight: 700;
        box-sizing: border-box;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 9rem;
          width: 16rem;
          height: 16rem;
          background: url(../images/common/ico_lang_down.svg) no-repeat center/contain;
          transform: translateY(-50%);
        }
      }
      .list {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        margin: 0;
        padding: 4rem 0 8rem;
        border: 1rem solid $color-black;
        border-top: none;
        border-radius: 0 0 16rem 16rem;
        background-color: $color-white;
        list-style: none;
        overflow: hidden;
        > li {
          + li {
            margin-top: 12rem;
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 0 11rem;
          color: inherit;
          font-size: 14rem;
          line-height: 17rem;
          font-weight: 300;
          text-decoration: none;
          &.active {
            text-decoration: underline;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.on {
        .active-lang {
          border-bottom-color: transparent !important;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          &::before {
            transform: translateY(-50%) rotate(180deg);
          }
        }
        .list {
          display: block;
        }
      }
    }
    .txt-fml {
      color: inherit;
      font-size: 14rem;
      font-weight: 700;
      font-family: $font-family-2;
      text-decoration: none;
    }
    .util {
      display: flex;
      padding: 0;
      margin: 0 0 0 40rem;
      list-style: none;
      li {
        position: relative;
        + li {
          margin-left: 12rem;
          padding-left: 13rem;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 1rem;
            height: 8rem;
            background: #d9d9d9;
            transform: translateY(-50%);
          }
        }
        a {
          color: inherit;
          font-size: 14rem;
          font-weight: 400;
          text-decoration: none;
        }
      }
    }
  }
  &cont {
    flex: 1;
    display: flex;
    .gate-menu {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-white;
        opacity: 1;
        visibility: visible;
        transition: 0.3s 0.3s;
      }
      &.start {
        &::after {
          opacity: 0;
          visibility: hidden;
        }
        > li {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
      > li {
        flex: 1;
        border-top: 1rem solid #efefef;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-40px);
        &:nth-child(1) {
          transition: 0.6s 0.2s linear;
        }
        &:nth-child(2) {
          transition: 0.6s 0.4s linear;
        }
        &:nth-child(3) {
          transition: 0.6s 0.6s linear;
        }
        &:nth-child(4) {
          transition: 0.6s 0.8s linear;
        }
        > a {
          display: block;
          position: relative;
          height: 100%;
          color: inherit;
          text-decoration: none;
          font-family: $font-family-2;
          outline: none;
          &:focus::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2);
            z-index: 1;
          }
          p {
            display: inline-flex;
            margin: 0;
            font-size: 140rem;
            line-height: 1;
            font-weight: 500;
            .colon {
              display: inline-flex;
              flex-direction: column;
              justify-content: space-between;
              align-self: center;
              position: relative;
              width: 17rem;
              height: 93rem;
              margin: 4rem 12rem 0;
              &::before,
              &::after {
                content: "";
                width: 17rem;
                height: 36rem;
                background: $color-black;
              }
            }
          }
          .slide-text {
            display: flex;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            --slide-width: 0;
            --timer: 6s;
            animation: menuFlow var(--timer) linear infinite;
            animation-play-state: paused;
            transition: all 0.2s ease;
            align-items: center;
            > p {
              display: inline-flex;
              width: auto;
              white-space: nowrap;
              align-items: center;
            }
          }
          .box-3d {
            &-wrap {
              perspective: 100vw;
              position: relative;
              width: 100%;
              height: 100%;
              overflow: hidden;
            }
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(calc(var(--slide-height) * -1)) rotateX(-90deg);
            transform-style: preserve-3d;
            transition: transform 0.6s cubic-bezier(0.5, 1, 0.25, 1);
            .face {
              @include flex-center(flex);
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              transition: 0.3s cubic-bezier(0.65, 0, 0.35, 1);
              overflow: hidden;
            }

            .face-front {
              transform: rotateY(0deg) translateZ(calc(var(--slide-height)));
            }

            .face-top {
              background-color: $color-white;
              color: $color-black;
              transform: rotateX(90deg) translateZ(calc(var(--slide-height)));
            }

            .face-back {
              background-color: $color-p;
              color: $color-white;
              transform: translateZ(calc(var(--slide-height) * -1)) rotateY(-180deg) rotate(180deg);
            }

            .face-bottom {
              transform: rotateX(-90deg) translateZ(calc(var(--slide-height)));
            }
          }
          .bg {
            position: absolute;
            top: 0;
            right: 96rem;
            bottom: 0;
            width: 0;
            @include bg-inline(cover);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
          }
          &:hover {
            .slide-text {
              opacity: 1;
              animation-play-state: running;
              transition: all 0.3s ease;
            }
            .box-3d {
              transform: translateZ(calc(var(--slide-height) * -1)) rotateX(-180deg);
              transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1);
              .face-top {
                background-color: rgba(0, 0, 0, 0.2);
                opacity: 0.3;
              }
            }
            .bg {
              width: 400rem;
              opacity: 1;
              visibility: visible;
              transition: 0.3s 0.3s;
            }
          }
        }
        &:nth-child(2n) {
          .text {
            margin: 0 164rem 0 auto;
          }
        }
        &:nth-child(2n + 3) {
          .text {
            margin: 0 auto 0 164rem;
          }
        }
      }
    }
    /* CMS 관련 layout 수정 */ 
    .row {
      margin-left:0;
      margin-right:0;
    }

    .col-md-12 {
      box-sizing:border-box;
    }

    .ts-theme-subpage-title {
      margin-top: 100rem;
    }
  }
  &cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 560rem;
    height: 360rem;
    margin-top: -180rem;
    margin-left: 20px;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    &.on {
      opacity: 1;
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include bg-inline(cover);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@keyframes menuFlow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--slide-width) * -1));
  }
}
@keyframes menuFlow2 {
  0% {
    transform: translateX(calc(var(--slide-width) * -1));
  }
  100% {
    transform: translateX(calc(var(--slide-width) * -2));
  }
}
