/* popup */
&layer {
  &-pop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    padding: 48rem;
    z-index: 500;
    overflow: auto;
    box-sizing: border-box;
    font-family: $font-family-2;

    .pop-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .pop-box {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 512rem;
      margin: auto;
      background: $color-white;
      box-shadow: 0 0 16rem rgba(0, 0, 0, 0.16);

      .in-head {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 24rem;

        &.bg-gray {
          background-color: #f5f6f8;
        }

        .title {
          margin: 0;
          color: $color-black;
          font-size: 24rem;
          line-height: 29rem;
          font-weight: 600;
        }

        .btn-close {
          position: absolute;
          top: 50%;
          right: 24rem;
          width: 24rem;
          height: 24rem;
          border: none;
          background: url(../images/common/ico_popup_close.svg) no-repeat center/contain;
          transform: translateY(-50%);
          text-indent: -999rem;
          overflow: hidden;
          cursor: pointer;
        }

        .do_next {
          display: inline-flex;
          margin-left: auto;
          color: #666;
          font-size: 14rem;
          line-height: 16rem;
          font-weight: 300;
          text-decoration: underline;
        }

        .stitle {
          margin: 4rem 0 0 0;
          color: $color-black;
          font-size: 16rem;
          line-height: 24rem;
          font-weight: 700;
        }

        .stext {
          margin: 0;
          color: $color-black;
          font-size: 16rem;
          line-height: 24rem;
          font-weight: 300;
        }
      }

      .in-cont {
        padding: 24rem 24rem 0;

        .num-box-wrap {
          .num-box {
            width: 65rem;
            height: 28rem;
            line-height: 28rem;
            margin: 0 auto;
            text-align: center;
            border-radius: 17rem;
            background: #1e75ff;
            margin-top: 24rem;

            span {
              font-family: $font-family-2;
              font-size: 12rem;
              color: #fff;
              font-weight: 300;

              &.tit-num {
                margin-right: 9rem;
                font-size: 16rem;
                font-weight: 500;
              }

              &.active {
                font-weight: 500;
              }
            }
          }

          .title {
            margin: 16rem 0 12rem;
            padding: 0;
            font-size: 20rem;
            font-weight: 600;
            text-align: center;
          }

          .text {
            font-size: 16rem;
            text-align: center;
            font-weight: 300;
            margin: 0;
            padding: 0;
          }
        }

        .quest-select-wrap {
          border: 1rem solid #eaeaea;
          padding: 24rem;
          margin-top: 8rem;

          .head-ico {
            display: flex;
            justify-content: flex-end;
            align-items: end;
            margin: -12rem 0 16rem;

            * {
              vertical-align: top;
            }
          }

          .btn-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 0;
            font-size: 0;

            .title {
              font-size: 16rem;
              font-weight: 500;
            }
          }

          .date-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16rem;

            .txt-wrap {
              .choose {
                color: #1e75ff;
                font-size: 14rem;
                font-weight: 500;
              }

              .title {
                margin: 0;
                padding: 0;
                font-weight: 500;
              }
            }
          }
        }

        .car-input-area {
          background: #f5f6f8;
          margin-top: 32rem;
          padding: 32rem 72rem;
          text-align: center;

          .ico {
            width: 40rem;
            height: 40rem;
            background: url(../images/common/ico_parking.svg) no-repeat center/contain;
            margin: 0 auto;
          }
          
          .label-text {
            margin-right: -72rem;
            margin-left: -72rem;
          }
        }

        .chk-btnlist {
          display: flex;
          flex-wrap: wrap;
          gap: 4rem;

          .item {
            flex: 0 0 calc(100% / 2 - 4rem * 1 / 2);
            cursor: pointer;

            input {
              display: none;

              &:checked+.btn {
                background-color: $color-black;
                color: $color-white;
                font-weight: 600;
              }
            }

            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 80rem;
              background-color: #f5f6f8;
              color: $color-black;
              font-size: 20rem;
              font-weight: 300;
            }
          }

          &.ts-col-3 {
            .item {
              flex: 0 0 calc(100% / 3 - 4rem * 2 / 3);
            }
          }

          &.gap-8 {
            gap: 8rem;

            .item {
              flex: 0 0 calc(100% / 2 - 8rem * 1 / 2);
            }
          }
        }

        .add-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11rem 16rem;
          border: 1rem solid #000;
          box-shadow: none;
          background-color: transparent;

          .ico-add {
            display: block;
            padding: 0 0 0 20rem;
            font-family: $font-family-1;
            font-size: 16rem;
            font-style: normal;
            font-weight: 300;
            background: url(../images/common/ico_add.svg) no-repeat left center;
            background-size: 16rem;
          }
        }
        &.family {
          padding:0 24rem;
          box-sizing:border-box;
          max-height:642rem;
          .num-box-wrap {
            .num-box {
              margin-top: 48rem;
            }
          }
        }
      }

      .in-foot {
        .pop-btn {
          flex: 0 1 240rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56rem;
          border: 1rem solid transparent;
          background: transparent;
          font-size: 16rem;
          line-height: 19rem;
          font-weight: 700;
          font-family: $font-family-2;
          text-decoration: none;
          box-sizing: border-box;
          cursor: pointer;

          &.bd-black {
            border-color: $color-black;
            color: $color-black;
          }

          &.bg-blue {
            border-color: transparent;
            background: $color-p;
            color: $color-white;
          }

          &box {
            display: flex;
            justify-content: center;
            padding: 40rem 24rem 24rem;

            *+* {
              margin-left: 8rem;
            }
          }
        }
      }

      &.policy {
        width: 848rem;
        font-size: 16rem;

        .in-cont {
          padding: 24rem;
        }

        .ts-theme-policy {
          overflow-y: auto;
          max-height: 520rem;
          font-size: 16rem;
          margin: 0 -24rem;
          padding: 0 24rem;

          .ts-theme-form {
            margin: 0;

            &+.item {
              margin: 24rem 0 0 0;
            }

            .selectstyle {
              width: 200rem;
            }
          }

          ol {
            & + h3 {
              margin-top:80rem;
            }
          }
          h3 {
            margin: 0 0 20rem 0;
            font-size: 24rem;
          }

          .item {
            &+.item {
              margin: 40rem 0 0 0;
            }
          }

          ol {
            >li {
              &+li {
                margin: 12rem 0 0 0;
              }

              &>ul {
                margin: 8rem 0 0 0;
              }

              p {
                margin: 8rem 0 0 0;
              }
            }
          }

          .list-dash {
            li {
              padding: 0 0 0 24rem;

              &:before {
                left: 12rem;
              }
            }
          }
        }
      }

      &.rent {
        width: 848rem;

        .in-cont {
          overflow-y: auto;
          max-height: 530rem;

          .pop-btnbox {
            display: flex;
            justify-content: center;
            padding: 40rem 24rem 24rem;

            .pop-btn {
              flex: 0 1 240rem;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 56rem;
              border: 1rem solid transparent;
              background: transparent;
              font-size: 16rem;
              line-height: 19rem;
              font-weight: 700;
              font-family: $font-family-2;
              text-decoration: none;
              box-sizing: border-box;
              cursor: pointer;

              &.bd-black {
                border-color: $color-black;
                color: $color-black;
              }

              &.bg-blue {
                border-color: transparent;
                background: $color-p;
                color: $color-white;
              }

              &box {
                display: flex;
                justify-content: center;
                padding: 40rem 24rem 24rem;

              }
              & + .pop-btn {
                margin-left: 8rem;
              }
            }
          }
        }

        i.asterisk {
          display: inline-block;
          vertical-align: middle;
          color: #FF1A1A;
          font-style: normal;
          font-weight: 700
        }

        .txt-essencial {
          margin: 0;
          padding: 0;
          color: $color-black;
          font-size: 16rem;
          font-weight: 300;
          line-height: 24rem;

          &+.ts-theme-form {
            margin-top: 16rem;
          }
        }

        .ts-theme-bo-table {
          table-layout: auto;
          tbody {
            tr {
              th {
                vertical-align: middle;
                padding: 20rem 24rem;
                width: 176rem;
                line-height: 29rem;
                &.vtop {
                  vertical-align: top;
                }
              }

              td {
                padding: 20rem 24rem;
                height: auto;
              }
            }
          }
        }

        .writing-count {
          position: absolute;
          bottom: 24rem;
          right: 24rem;
          margin: 0;
          color: #999;
          font-family: $font-family-2;
          font-size: 14rem;
          font-weight: 300;
          line-height: 16rem;

          b {
            color: $color-p;
          }
        }

        .box-policy {
          .item {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            .txt {
              font-size: 16rem;
              font-weight: 600;
            }

            .input-labelbox {
              margin-right: 12rem;
            }

            &>a {
              display: inline-block;
              padding: 0 16rem 0 0;
              color: $color-p;
              font-size: 14rem;
              font-weight: 500;
              text-decoration: none;
              background: url(../images/common/ico_rent_arrow.svg) no-repeat right center/16rem 16rem;
            }

            &+.item {
              margin: 24rem 0 0;
            }
          }
        }
      }
    }

    .pop-coupon {
      margin: auto;

      .content {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 328rem;
        border-radius: 16rem 16rem 0 0;
        margin: 0 0 28rem 0;
        padding: 64rem 24rem 20rem;
        background-color: $color-white;
        box-sizing: border-box;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -28rem;
          width: 100%;
          height: 28rem;
          background: url(../images/common/bg_coupon_hole.png) no-repeat center center;
          background-size: 100%;
        }

        .btn-close {
          position: absolute;
          top: 24rem;
          right: 24rem;
          width: 24rem;
          height: 24rem;
          border: none;
          background: url(../images/common/ico_popup_close.svg) no-repeat center/contain;
          text-indent: -999rem;
          overflow: hidden;
          cursor: pointer;
        }

        .box {
          position: relative;
          flex-grow: 1;

          .badge {
            display: inline-block;
            margin:0 0 16rem;
            padding: 0rem 12rem;
            border-radius:0;
            font-size: 14rem;
            line-height: 28rem;
            color: #fff;
            font-weight: 700;
            background-color: $color-black;
            border-radius: 16rem;
          }

          .tit {
            display: block;
            font-size: 20rem;
            font-weight: 700;
            line-height: 24rem;
          }

          .desc {
            margin-top: 8rem;
            display: block;
            font-size: 16rem;
            font-weight: 300;
            line-height: 24rem;
            color: #666666;
          }

          .date {
            margin-top: 24rem;
            display: block;
            color: #999999;
            font-family: $font-family-2;
            font-size: 12rem;
            font-weight: 500;
            line-height: 15rem;
          }
        }

        .barcode {
          position: relative;
          margin: 72rem 0 0 0;
          text-align: center;

          .img-barcode {
            opacity: 0.1;

            img {
              display: block;
              width: 100%;
              max-width: 279rem;
              margin: 0 auto;
            }
          }

          .txt {
            position: absolute;
            top: 20rem;
            left: 50%;
            margin: 0;
            width: 100%;
            font-weight: 600;
            transform: translateX(-50%);
          }
        }
      }

      .notice {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 328rem;
        border-radius: 0 0 16rem 16rem;
        padding: 20rem 24rem 34rem;
        box-sizing: border-box;
        background-color: #f5f6f8;

        .sec-title {
          display: flex;
          align-items: flex-start;
          margin: 0 0 16rem 0;
          padding: 0;

          .ico {
            display: inline-block;
            margin-right: 8rem;
            width: 14rem;
            height: 24rem;
            background: url(../images/common/ico_info02.svg) no-repeat left center;
            background-size: 14rem;
            border-radius: 50%;
          }

          .title {
            display: inline-block;
            margin: 0;
            padding: 0;
            width: calc(100% - 22rem);
            font-size: 16rem;
          }
        }

        .list-desc {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            position: relative;
            padding: 0 0 0 6rem;
            color: #666;
            font-size: 14rem;
            font-weight: 300;

            &:before {
              display: block;
              position: absolute;
              top: 8rem;
              left: 0;
              width: 2rem;
              height: 2rem;
              background-color: #666;
              content: "";
            }

            &+li {
              margin: 8rem 0 0 0;
            }
          }
        }
      }
    }

    .pop-commercial {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: auto;
      background: $color-white;

      .content {
        margin: 0;
        padding: 0;

        a,
        img {
          display: block;
        }

        a {
          overflow-y: auto;
        }

        img {
          width: 100%;
        }
      }

      .ts-theme-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16rem 24rem;
        box-sizing: border-box;

        .input-labelbox {
          .txt {
            color: #666;
          }
        }

        .btn-txt {
          overflow: visible;
          padding: 0;
          border: none;
          box-shadow: none;
          border-radius: 0;
          color: $color-black;
          font-family: $font-family-1;
          font-size: 16rem;
          font-weight: 300;
          background: inherit;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

&floor-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  padding: 48rem;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  overflow: auto;
  z-index: 999;

  &.vis-opa {
    display: flex !important;
    visibility: hidden;
    opacity: 0;
  }

  .in-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .in-cont {
    display: flex;
    position: relative;
    width: 100%;
    margin: auto;
    background-color: $color-white;

    .btn-pop-close {
      position: absolute;
      top: 0;
      right: -44rem;
      width: 32rem;
      height: 32rem;
      border: none;
      border-radius: 50%;
      background: url(../images/common/btn_close.svg) no-repeat center/contain;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .floor-tab {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96rem;
    padding: 64rem 0;
    margin: 0;
    background-color: $color-black;
    list-style: none;

    >li {
      +li {
        margin-top: 56rem;
      }

      >a {
        display: block;
        color: $color-white;
        font-size: 28rem;
        line-height: 33rem;
        font-weight: 500;
        font-family: $font-family-2;
        text-decoration: none;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }

        &.active {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    &con {
      flex: 1;
      padding: 64rem;

      .title-box {
        display: flex;

        .title {
          margin: 0 24rem 0 0;
          font-size: 48rem;
          line-height: 58rem;
          font-weight: 700;
          font-family: $font-family-2;
        }

        .types {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 16rem 0 0 0;
          list-style: none;

          >li {
            position: relative;
            padding-right: 17rem;
            margin-right: 16rem;
            font-size: 24rem;
            line-height: 29rem;
            font-weight: 500;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              width: 1rem;
              height: 12rem;
              background-color: $color-black;
              transform: translateY(-50%);
            }

            &:last-child {
              margin-right: 0;
              padding-right: 0;

              &::before {
                display: none;
              }
            }
          }
        }
      }

      .facilities-box {
        margin-top: 24rem;

        .stitle-box {
          display: flex;
          align-items: center;

          .stitle {
            margin: 0 8rem 0 0;
            font-size: 24rem;
            line-height: 29rem;
            font-weight: 700;
          }
        }

        .icon-list {
          display: flex;
          flex-wrap: wrap;
          margin: 8rem 0 0 -8rem;
          padding: 0;
          list-style: none;

          >li {
            display: inline-flex;
            align-items: center;
            padding: 7rem 11rem;
            margin: 8rem 0 0 8rem;
            border: 1rem solid $color-black;

            .img {
              margin-right: 8rem;

              img {
                width: 24rem;
                height: 24rem;
                vertical-align: top;
              }
            }

            .txt {
              font-size: 16rem;
              line-height: 24rem;
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  /* 매장상세 */
  .floor-store {
    display: flex;
    flex-direction: column;
    width: 100%;

    .store-head {
      position: relative;

      .btn-close-detail {
        position: absolute;
        top: 32rem;
        right: 32rem;
        width: 32rem;
        height: 32rem;
        border: none;
        background-color: transparent;
        @include bg-inline;
        background-image: url(../images/common/btn_white_close.svg);
        overflow: hidden;
        cursor: pointer;
        z-index: 1;
      }

      .img-visual {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 480rem;
        padding: 32rem 48rem;
        @include bg-inline(cover);
        background-color: #f5f6f8;
        box-sizing: border-box;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $color-black;
          opacity: 0.1;
        }

        .cont {
          position: relative;
          min-width: 425rem;
          padding: 24rem;
          background-color: rgba(0, 0, 0, 0.8);
          color: $color-white;
          font-family: $font-family-2;

          .titbox {
            display: flex;
            align-items: center;
            font-size: 32rem;
            line-height: 39rem;

            .tit {
              font-weight: 700;
            }

            .f {
              margin-left: 16rem;
              font-weight: 300;
              a {
                color:inherit;
                text-decoration: none;
              }
            }

            .heart {
              display: inline-flex;
              margin-left: 8rem;

              input {
                width: 32rem;
                height: 32rem;
                background-image:url(../images/common/ico_heart_transparent_off.svg);
                &:checked {
                  background-image:url(../images/common/ico_heart_on.svg);                  
                }
              }
            }
          }

          .typebox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: hidden;

            >li {
              display: flex;
              position: relative;
              margin: 16rem 16rem 0 0;
              padding-right: 17rem;

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 1rem;
                height: 12rem;
                background-color: $color-white;
                transform: translateY(-50%);
              }

              &:last-child {
                margin-right: 0;
                padding-right: 0;

                &::before {
                  display: none;
                }
              }

              >span {
                font-size: 20rem;
                line-height: 24rem;
                font-weight: 300;

                &.event {
                  padding: 0 15rem;
                  border: 1rem solid $color-white;
                  font-size: 16rem;
                  line-height: 30rem;
                }
              }
            }
          }

          .contactbox {
            margin: 40rem 0 0 0;
            padding: 0;
            list-style: none;

            >li {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;

              +li {
                margin-top: 12rem;
              }

              .ico {
                flex: none;
                display: inline-flex;
                align-items:center;
                margin: 0 8rem 0 0;

                img {
                  width: 18rem;
                  height: 18rem;
                  margin-right:8rem;
                }
                span {
                  display:inline-block;
                  vertical-align: top;
                }
              }

              .txt {
                padding:2rem 0 0 0; 
                font-size: 16rem;
                line-height: 20rem;
                font-weight: 300;
                span {
                  display:block;
                }
              }
            }
          }
        }
      }
    }

    .store-cont {
      padding: 48rem;
      background-color: $color-white;

      .store-news {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 20rem 24rem;
        text-decoration: none;
        background-color: #f5f6f8;

        .ico {
          flex: none;
          width: 24rem;
          height: 24rem;
          margin-right: 12rem;
          background: url(../images/common/ico_black_alarm.svg) no-repeat center center;
        }

        .txt {
          flex: 1;
          color:$color-black;
          font-size: 20rem;
          line-height: 24rem;
          font-weight: 300;
          a {
            color: $color-black;
            text-decoration: none;
          }
        }
        & + .store-map {
          margin-top: 32rem;
        }
      }
    }
  }

  /* floor-map-box */
  .floor-map {
    position: relative;
    width: 1080rem;
    height: 400rem;
    margin-top: 8rem;
    border: 1rem solid #d9d9d9;
    background-color: transparent;
    box-sizing: border-box;
    overflow: hidden;
    .ts-icon-map {
      z-index:999 !important;
    }
  }

  .store-map {
    overflow: hidden;
    border: 1rem solid #d9d9d9;
    box-sizing: border-box;
    width: 960rem;
    height: 440rem;
    background-color:transparent;
    .ts-icon-map {
      z-index:999 !important;
    }
  }

  .leaflet-container .leaflet-right .leaflet-control-zoom {
    margin-right: 16rem;
    margin-bottom: 16rem;
    border: none;
    border-radius: 0;

    a {
      width: 32rem;
      height: 32rem;
      margin: 0 0 1rem 0;
      border: none;
      border-radius: 8rem;
      @include bg-inline;
      color: transparent;
      box-sizing: border-box;

      &.leaflet-control-zoom-in {
        background-image: url(../images/common/btn_map_plus.svg);
      }

      &.leaflet-control-zoom-out {
        background-image: url(../images/common/btn_map_minus.svg);
      }

      &.leaflet-disabled {
        background-color: transparent;
        opacity: 0.35;
      }
    }
  }

  .leaflet-marker-pane {
    .custom-label {
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 500;
        font-family: $font-family-2;
        text-shadow: 0 0 5px #fff;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        transition: 0.1s linear;
        pointer-events: none;
      }
    }
  }
}