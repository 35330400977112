/* board */
&bo {
  &-search-box {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 460rem;
    margin-left: auto;
    box-sizing: border-box;
    input {
      width: 100%;
      height: 56rem;
      padding: 18rem 56rem 17rem 16rem;
      border: 1rem solid transparent;
      background: #f5f6f8;
      font-size: 16rem;
      line-height: 19rem;
      font-weight: 300;
      font-family: $font-family-1;
      outline: none;
      box-sizing: border-box;
      &:focus {
        border-color: $color-black;
      }
    }
    .btn-search {
      position: absolute;
      top: 15rem;
      right: 15rem;
      width: 24rem;
      height: 24rem;
      border: none;
      background: url(../images/common/ico_search.svg) no-repeat center/contain;
      text-indent: -999rem;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  &-row-list {
    padding: 0;
    margin: 0;
    border-top: 2rem solid $color-black;
    list-style: none;
    > li {
      border-bottom: 1rem solid #eaeaea;
      * {
        color: inherit;
        text-decoration: none;
      }
      > .inbox {
        display: flex;
        align-items: center;
        min-height: 125rem;
        padding: 40rem 0;
        font-size: 20rem;
        line-height: 24rem;
        box-sizing: border-box;
        .num-box {
          flex: none;
          width: 120rem;
          margin-right: 16rem;
          font-family: $font-family-2;
          text-align: center;
        }
        .tit-box {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 0 24rem;
          overflow: hidden;
          .bo-type {
            flex: none;
            width: 72rem;
            margin-right: 12rem;
            border: 1rem solid $color-black;
            font-size: 14rem;
            line-height: 27rem;
            font-weight: 700;

            text-align: center;
            box-sizing: border-box;
            & + .tit {
              flex: 1;
            }
          }
          .tit {
            margin: 0;
            font-size: 24rem;
            line-height: 29rem;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            outline-offset: -1px;
            & + .bo-type {
              margin: 0 0 0 20rem;
            }
          }
        }
        .date-box {
          flex: none;
          width: 160rem;
          color: #666;
          font-family: $font-family-2;
          text-align: center;
        }
        .state-box {
          width: 176rem;
          margin-left: 24rem;
          text-align: center;
        }
        .store-box {
          display: flex;
          align-items: center;
          margin-right: auto;
          .imgbox {
            flex-shrink: 0;
            width: 240rem;
            height: 160rem;
            @include bg-inline(cover);
            // background-color: #000;
          }
          .namebox {
            display: flex;
            align-items: center;
            margin:0 24rem 0 40rem;
            font-size: 24rem;
            font-weight: 500;
            line-height: 29rem;
            .floor {
              flex-shrink: 0;
              margin-left: 8rem;
              padding: 4rem 12rem 3rem 11rem;
              border: 1rem solid $color-black;
              border-radius: 16rem;
              font-size: 16rem;
              font-weight: 700;
              font-family: $font-family-2;
              line-height: 19rem;
            }
          }
        }
        .fav-box {
          display: flex;
          flex-shrink: 0;
          width: 24rem;
          text-align: center;
        }
        .floor-box {
          flex-shrink: 0;
          width: 88rem;
          text-align: center;
          font-family: $font-family-2;
        }
        .type-box {
          flex-shrink: 0;
          width: 128rem;
          margin-left: 24rem;
          font-family: $font-family-2;
          text-align: center;
        }
        .time-box {
          flex-shrink: 0;
          margin-left: 24rem;
          width: 192rem;
          text-align: center;
          .work-time {
            margin: 0;
            font-size: 20rem;
            line-height: 30rem;
            font-family: $font-family-2;
          }
          .work-time-desc {
            margin-top: 1rem;
            color: #707070;
            font-size: 16rem;
            line-height: 19rem;
          }
        }
        .contact-box {
          flex-shrink: 0;
          margin: 0 24rem;
          width: 208rem;
          font-family: $font-family-2;
          text-align: center;
        }
      }
    }
    &.floor {
      margin-top: 64rem;
      > li {
        border-color: $color-black;
        .tit-box {
          padding: 0 24rem 0 0;
          .tit {
            font-weight: 500;
          }
          .bo-type {
            padding: 8rem 12rem;
            width: auto;
            font-size: 16rem;
            font-weight: 700;
            line-height: 1;
          }
        }
        > .inbox {
          min-height: 157rem;
        }
      }
    }
  }
  &-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 32rem;
    padding: 0;
    margin: 0;
    list-style: none;
    > li {
      flex-basis: calc(100% / 2 - 32rem / 2);
      transition-delay: 0s;
      &.aos-init:nth-child(2n + 2) {
        transition-delay: 0.3s;
      }
      > .inbox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        min-height: 233rem;
        padding: 40rem;
        border: 1rem solid #eaeaea;
        color: #111;
        text-decoration: none;
        box-sizing: border-box;
        .toptype {
          display: inline-flex;
          padding: 7rem 12rem;
          border: 1rem solid #707070;
          font-size: 14rem;
          font-weight: 700;
          line-height: 14rem;
        }
        .tit {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          margin: 13rem 0 0 0;
          font-size: 20rem;
          font-weight: 300;
          line-height: 30rem;
        }
        .date {
          margin-top: auto;
          color: #666;
          font-family: $font-family-2;
          font-size: 14rem;
          font-weight: 300;
          line-height: 15rem;
        }
        &:hover {
          border-color: $color-p;
          * {
            color: $color-p;
          }
          .toptype {
            border-color: $color-p;
          }
        }
      }
      &.wide {
        flex-basis: 100%;
        .ts-theme-bo-no-content {
          border-top:2rem solid $color-black;
          border-bottom:1rem solid #eaeaea;
        }
      }
    }
    &.col3 > li {
      flex-basis: calc(100% / 3 - 64rem / 3);
    }
  }
  &-image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 120rem 32rem;
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      flex: 0 0 calc(100% / 3 - 64rem / 3);
      min-width: 0;
      transition-delay: 0s;
      &.aos-init:nth-child(3n + 2) {
        transition-delay: 0.3s;
      }
      &.aos-init:nth-child(3n + 3) {
        transition-delay: 0.6s;
      }
      > .inbox {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        .imgbox {
          overflow:hidden;
          display: flex;
          position: relative;
          img {
            width: 100%;
            transform: scale(1);
            transition: transform .3s;
          }
          .tag {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 6rem 12rem;
            color: $color-white;
            font-family: $font-family-2;
            font-size: 14rem;
            font-weight: 700;
            line-height: 17rem;
            background-color: $color-black;
          }
          .fav-box {
            display:flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index:2;
            width:32rem;
            height:32rem;
            background-color:rgba(0,0,0,.48);
            .ts-theme-check-heart {
              width:24px;
              height:24px;
              background-image:url(../images/common/ico_heart_transparent_off.svg);
              &:checked {
                background-image:url(../images/common/ico_heart_white.svg);
              }
            }
          }
          .txt-ended {
            @include flex-center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.64);
            color: $color-white;
            font-size: 20rem;
            line-height: 24rem;
            font-weight: 500;
            text-align: center;
            z-index: 1;
          }
        }
        .txtbox {
          .tit {
            display: block;
            margin: 24rem 0 0 0;
            color: #111;
            font-size: 24rem;
            line-height: 29rem;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .date {
            display: block;
            margin-top: 16rem;
            color: #999;
            font-size: 16rem;
            line-height: 26rem;
            font-weight: 500;
            letter-spacing: -0.0165rem;
            font-family: $font-family-2;
          }
        }
        &:hover,
        &:focus {
          .imgbox {
            img {
              transform: scale(1.2);
              transition: transform .3s;
            }
          }
        }
      }
    }
  }
  &-coupon-list {
    margin-top: 24rem;
    display: flex;
    gap: 32rem;
    flex-wrap: wrap;
    > [class*="box-app-layer"] {
      width: calc(100% / 3 - 64rem / 3);
      transition-delay: 0s;
      &.aos-init:nth-child(3n + 2) {
        transition-delay: 0.3s;
      }
      &.aos-init:nth-child(3n + 3) {
        transition-delay: 0.6s;
      }
    }
  }
  &-faq {
    margin: 0;
    padding: 0;
    border-top: 2rem solid #111;
    color: #111;
    list-style: none;
    > li {
      border-bottom: 1rem solid #eaeaea;
      .titbox {
        display: flex;
        position: relative;
        padding-right: 100rem;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 30rem;
          width: 40rem;
          height: 40rem;
          margin-top: -20rem;
          background: url(../images/common/btn_open.svg) no-repeat center/contain;
          transition: 0.3s;
        }
        &.on {
          .tit {
            font-weight: 700;
          }
          &::before {
            transform: rotate(180deg);
          }
        }
        .tittype {
          flex: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 232rem;
          padding: 48rem 24rem;
          font-size: 24rem;
          line-height: 29rem;
          text-align: center;
          font-style: normal;
          font-weight: 300;
          box-sizing: border-box;
        }
        .tit {
          flex: 1;
          display: flex;
          align-items: center;
          margin: 0;
          padding-left: 66rem;
          font-size: 24rem;
          line-height: 29rem;
          font-weight: 300;
        }
      }
      .conbox {
        display: none;
        padding: 48rem 40rem;
        background: #f5f6f8;
        font-size: 20rem;
        line-height: 30rem;
        font-weight: 300;
        p {
          margin: 0;
        }
      }
    }
  }
  &-paging {
    display: flex;
    justify-content: center;
    align-items: center;
    a,
    .txt-dot {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 48rem;
      height: 48rem;
      margin: 0 8rem;
      color: inherit;
      font-size: 16rem;
      font-weight: 500;
      font-family: $font-family-2;
      text-decoration: none;
      box-sizing: border-box;
    }
    [class*="btn-"] {
      margin: 0 28rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24rem;
      text-indent: -999rem;
      overflow: hidden;
      &.disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
      }
    }
    .btn-prev {
      background-image: url(../images/common/btn_paging_prev.svg);
    }
    .btn-next {
      background-image: url(../images/common/btn_paging_next.svg);
    }
    .active {
      background-color: $color-p;
      color: $color-white;
    }
  }
  &-view- {
    &titbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 220rem 40rem 120rem;
      background: #f5f6f8;
      .toptype {
        padding: 8rem 16rem;
        border: 1rem solid #111;
        background: $color-white;
        font-size: 16rem;
        line-height: 19rem;
        font-weight: 700;
        & + .tit {
          margin: 32rem 0 0 0;
        }
      }
      .tit {
        max-width: 1600rem;
        margin: 0;
        font-family:$font-family-2;
        font-size: 40rem;
        font-weight: 700;
        line-height: 48rem;
        letter-spacing: -0.012em;
      }
      .date {
        margin-top: 48rem;
        color: #999;
        font-family: $font-family-2;
        font-size: 20rem;
        line-height: 20rem;
        font-weight: 500;
      }
    }
    &conbox {
      padding: 120rem 40rem;
      border-bottom: 1rem solid #eaeaea;
      font-family: $font-family-2;
    }
  }
  &-inquiry {
    border-top: 2rem solid #111;
    color: $color-black;
    list-style: none;
    .questionbox {
      background-color: #fff;
      .titbox > span:not(.date) {
        color: #1f75ff;
      }
    }
    .answerbox {
      background-color: #f5f6f8;
      .titbox {
        padding: 48rem 40rem 0;
      }
    }
    .titbox {
      padding: 48rem 40rem 32rem;
      display: flex;
      align-items: flex-start;
      font-family: $font-family-2;
      color: #000;
      > span:first-of-type {
        margin-top: -7rem;
        font-size: 32rem;
        line-height: 39rem;
        font-weight: 700;
        color: #000000;
        flex: 0 0 60rem;
      }
      .tit {
        margin: 0;
        flex: 0 1 100%;
        font-size: 24rem;
        line-height: 29rem;
        font-weight: 700;
      }
      .text {
        margin:0;
        padding:0;
      }
      .date {
        font-size: 20rem;
        line-height: 29rem;
        font-weight: 300;
        color: #999999;
        flex: 0 0 160rem;
        text-align: right;
        margin-left: auto;
        font-family: $font-family-2;
      }
      .text {
        font-size: 20rem;
        font-weight: 300;
      }
    }
    .conbox {
      padding: 0 40rem 50rem 100rem;
      > p {
        margin: 0;
        font-size: 20rem;
        font-weight: 300;
        line-height: 30rem;
      }
      .file {
        display: block;
        margin-top: 24rem;
        padding: 0 0 0 32rem;
        color: $color-black;
        font-size: 16rem;
        font-weight: 300;
        line-height: 24rem;
        text-decoration: underline;
        background: url(../images/common/ico_upload.svg) no-repeat left center;
        background-size: 24rem 24rem;
      }
    }
  }
  &-prev-next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 64rem;
    font-family: $font-family-2;
    .btn-prev {
      position: relative;
      padding-left: 32rem;
      color: $color-black;
      font-size: 20rem;
      line-height: 30rem;
      font-weight: 700;
      text-decoration: none;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 4rem;
        width: 20rem;
        height: 20rem;
        background: url(../images/common/ico_view_prev.svg) no-repeat center/contain;
        transform: translateY(-9rem);
      }
    }
    .btn-next {
      position: relative;
      padding-right: 32rem;
      color: $color-black;
      font-size: 20rem;
      line-height: 30rem;
      font-weight: 700;
      text-decoration: none;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 4rem;
        width: 20rem;
        height: 20rem;
        background: url(../images/common/ico_view_next.svg) no-repeat center/contain;
        transform: translateY(-9rem);
      }
    }
    .disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }
    .btn-list {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 320rem;
      height: 64rem;
      background: $color-p;
      color: #fff;
      font-size: 20rem;
      line-height: 30rem;
      font-weight: 700;
      font-family: $font-family-2;
      text-decoration: none;
    }
  }
  &-table {
    width: 100%;
    table-layout: fixed;
    border-top: 2rem solid $color-black;
    border-spacing: 0;
    > caption {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
    .th-col {
      width: 160rem;
    }
    > tbody {
      > tr {
        > th {
          padding: 48rem 24rem;
          border-bottom: 1rem solid #eaeaea;
          color: $color-black;
          font-size: 20rem;
          line-height: 24rem;
          font-weight: 500;
          box-sizing: border-box;
          text-align: left;
          vertical-align: top;
        }
        > td {
          height: 120rem;
          padding: 24rem 74rem 24rem 48rem;
          border-bottom: 1rem solid #eaeaea;
          font-size: 16rem;
          line-height: 19rem;
          box-sizing: border-box;
        }
      }
    }
  }
  /* other  */
  &-no-content {
    @include flex-center(flex);
    flex-direction: column;
    min-height: 425rem;
    padding: 64rem 0;
    box-sizing: border-box;
    & > .txtbox {
      width: 100%;
      text-align: center;
      .ico-error {
        width: 64rem;
        height: 64rem;
        margin: 0 auto;
        background: url(../images/common/ico_no_result.svg) no-repeat center/contain;
      }
      .txt {
        margin: 16rem 0 0 0;
        font-size: 20rem;
        line-height: 30rem;
        letter-spacing: -0.016em;
        text-align: center;
        .txt-heart {
          display: inline-flex;
          width: 19rem;
          height: 16rem;
          background-position: center;
          // background: #000;
        }
      }
    }
    /* 추가내용 있을시 */
    .store-link-list {
      @include flex-center(flex);
      width: 100%;
      margin: 48rem 0 0 0;
      padding: 0;
      list-style: none;
      & > li {
        margin: 0 8rem;
        .store-link {
          @include flex-center(flex);
          flex-direction: column;
          width: 240rem;
          height: 240rem;
          background-color: #f5f6f8;
          color: inherit;
          .ico {
            width: 64rem;
            height: 64rem;
            @include bg-inline;
          }
          .txt {
            margin: 20rem 0 0 0;
            font-family: $font-family-2;
            font-size: 24rem;
            font-weight: 700;
            letter-spacing: -0.016em;
            line-height: 29rem;
          }
        }
      }
    }
  }
  &-event- {
    &view-wrap {
      padding: 0 0 120rem;
      border-bottom: 1rem solid #eaeaea;
    }
    &view-conbox {
      padding: 120rem 40rem 0;
      font-family: $font-family-2;
      p {
        margin:0;
        padding:0;
      }
      img {
        display:block;
        margin:0 auto;
        width:100%;
        max-width:800rem;
        height:auto;
      }
      &.type02 {
        img {
          max-width:640rem;
        }
      }
    }
    &action {
      // 임시
      .wrap-attendance-check {
        display:flex;
        flex-wrap:wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin:0 auto;
        padding:32rem 40rem;
        border-radius: 0 0 40rem 40rem;
        box-sizing:border-box;
        width:640rem;
        background-color:#F5F6F8;
        .attendance {
          margin:8rem;
          padding:20rem 12rem 12rem;
          border:1px solid #EAEAEA;
          border-radius:48rem;
          box-sizing:border-box;
          width:96rem;
          height:172rem;
          font-family: $font-family-2;
          background-color:#fff;
          text-align:center;
          span {
            display:none;
            font-family: inherit;
            font-size:20rem;
            line-height:24rem;
          }
          b {
            display:block;
            margin:8rem 0 24rem;
            font-family: inherit;
            font-size:32rem;
            font-weight: 300;
            line-height: 36rem;            
          }
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            margin:8rem 0 0 0;
            border-radius: 50%;
            width:72rem;
            height:72rem;
            background-color:#F5F6F8;
            span {
              display:block;
              font-family: inherit;
              font-style:normal;
              font-size:22rem;
              line-height:1;  
            }
          }
          &.attended {
            border-color: #692AE0;
            background-color: #692AE0;
            span,
            b {
              color:$color-white
            }
            i {
              background:rgba(255,255,255,.15) url(../images/common/ico_attendance_checked.svg) no-repeat center center;
              background-size: 24rem;
              span {
                display:none;
              }
            }
          }
          &.passed {
            border-color: #692AE0;
            background-color: #692AE0;
            span,
            b {
              color:$color-white;
            }
            i {
              background-color:rgba(255,255,255,.15);
              background-size: 24rem;
            }
          }
        }
      }
    }
    &inducing-app {
      margin: 80rem 0 0 0;
      text-align: center;
      .title {
        margin: 0;
        font-size: 24rem;
        line-height: 29rem;
      }
      .txt {
        margin: 16rem 0;
        font-size: 20rem;
        line-height: 24rem;
      }
      .box-links {
        @include flex-center(flex);
        margin: 32rem 0;
        .link {
          display: block;
          width: 160rem;
          text-decoration: none;
          img {
            display: block;
            width: 100%;
          }
          & + .link {
            margin-left: 8rem;
          }
        }
      }
      & + .ts-theme-msgbox {
        margin-top:64rem;
      }
    }
    &chips {
      @include flex-center(flex);
      margin: 32rem 0 0 0;
      .ts-theme-check-wrap {
        display: flex;
        align-items: center;
        padding: 14rem 24rem;
        border-radius: 24rem;
        color: $color-black;
        font-family: $font-family-1;
        font-size: 16rem;
        font-weight: 300;
        background-color: #f5f6f8;
        cursor: pointer;
        .ts-theme-ico-checkbox {
          margin: 0;
          width: 20rem;
          height: 20rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          appearance: none;
          &.ico-thumb-like {
            background-image: url(../images/common/ico_thumb_like.svg);
          }
          &.ico-face-smile {
            background-image: url(../images/common/ico_face_smile.svg);
          }
          &.ico-face-laugh {
            background-image: url(../images/common/ico_face_laugh.svg);
          }
          &.ico-face-love {
            background-image: url(../images/common/ico_face_love.svg);
          }
        }
        .text {
          margin: 0 0 0 8rem;
          line-height: 24rem;
        }
        .number {
          margin: 0 0 0 4rem;
          line-height: 24rem;
        }
        & + .ts-theme-check-wrap {
          margin: 0 0 0 12rem;
        }
      }
    }
  }
}

// 지금 타임스퀘어는
&now {
  padding: 120rem 160rem;
  background-color: #f5f6f8;
  overflow: hidden;
  .swiper-container {
    margin-right: -32rem;
    padding-bottom: 52rem;
    overflow: visible;
    .swiper-wrapper {
      margin: 0;
      padding: 0;
      list-style: none;
      .swiper-slide {
        .inbox {
          display: block;
          position: relative;
          margin-right: 32rem;
          color: $color-white;
          text-decoration: none;
          overflow: hidden;
          
          &.event {
            border-radius: 50%;
          }
          .imgbox {
            overflow:hidden;
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              vertical-align: top;
              transform:scale(1);
              transition: transform .3s;
            }
          }
          .txtbox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.64);
            text-align: center;
            visibility: hidden;
            opacity: 0;
            transition: 0.6s;
            .tit {
              font-size: 32rem;
              line-height: 48rem;
              font-weight: 700;
            }
            .info {
              display: flex;
              align-items: center;
              padding: 0;
              margin: 32rem 0 0 0;
              list-style: none;
              > li {
                position: relative;
                font-size: 20rem;
                line-height: 24rem;
                font-weight: 500;
                font-family: $font-family-2;
                + li {
                  margin-left: 8rem;
                  padding-left: 9rem;
                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1rem;
                    height: 12rem;
                    background-color: $color-white;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
          
          &:hover,
          &:focus {
            .imgbox {
              img {
                transform:scale(1.2);
                transition: transform .3s;
              }
            }
            .txtbox {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      top: auto;
      right: 32rem;
      bottom: 0;
      left: 0;
      width: auto;
      background: rgba(0, 0, 0, 0.08);
    }
    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-fill {
        background: $color-black;
      }
    }
  }
}

// 공지사항 상단 탭 추가
&notice-tool {
  display:flex;
  justify-content:space-between;
  margin-top:120px;
}