/* table */
&tbl {
  &-col {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    > caption {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
    > thead {
      > tr {
        > th {
          padding: 24rem;
          border-top: 1rem solid $color-black;
          border-bottom: 1rem solid $color-black;
          color: $color-black;
          font-size: 18rem;
          line-height: 24rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          padding: 24rem;
          border-bottom: 1rem solid #e5e5e5;
          color: $color-black;
          font-size: 18rem;
          line-height: 24rem;
          font-weight: 300;
          text-align: center;
        }
      }
    }
  }

  &-row {
    width: 100%;
    border-top: 1rem solid $color-black;
    border-collapse: collapse;
    border-spacing: 0;
    > caption {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
    > tbody {
      > tr {
        > th {
          padding: 24rem 0;
          border-bottom: 1rem solid #e5e5e5;
          color: $color-black;
          font-size: 18rem;
          line-height: 24rem;
          font-weight: 700;
          text-align: left;
        }
        > td {
          padding: 24rem 0;
          border-bottom: 1rem solid #e5e5e5;
          color: $color-black;
          font-size: 18rem;
          line-height: 24rem;
          font-weight: 300;
          text-align: left;
        }
      }
    }
  }
}
