&introduce-content {
  position: relative;
  z-index: 1;
  height: 220vh;
  font-family: $font-family-2;
  
  .scroll-bigger-box {
    position: sticky;
    top: 0;
    margin: 0 auto;
    height: 100vh;

    .scroll-text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .scroll-title {
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: center;
        font-size: 72rem;
        font-weight: 700;

        span {
          display: block;

          &.scene01 {
            opacity: 1;
          }

          &.scene02 {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            font-size: 96rem;
            transform: translateY(-50%);

            b {
              display: block;
              font-family: $font-family-2;
              text-transform: uppercase;

              &:nth-child(1) {
                text-align: left;
                opacity: 0;
                transform: translateX(-10%);
              }

              &:nth-child(2) {
                text-align: right;
                opacity: 0;
                transform: translateX(20%);
              }

              &:nth-child(3) {
                text-align: center;
                opacity: 0;
                transform: translateX(-20%);
              }
            }
          }
        }
      }
    }

    .bg {
      display: block;
      margin: 0 auto;
      width: 1460rem;
      height: 100%;
      box-shadow: inset 2000rem 2000rem rgba(0, 0, 0, .3);
      background: url(../images/sub/img_bg_timessquare_introduction.png) no-repeat center center;
      background-size: cover;
    }
  }
}

// section-about
&section-about {
  position: relative;
  width: 100%;

  .inner-section {
    padding-bottom:120rem;
    .about-title {
      position: sticky;
      top: 50%;
      z-index: 2;
      margin: 0;
      transform: translateY(-50%);
      mix-blend-mode: color-burn;

      .txt-about {
        display: block;
        font-family: $font-family-2;
        color: #222;
        font-size: 64rem;
        font-weight: 700;
        text-align: center;
        opacity: 0;
        // mix-blend-mode: color-burn;

        &.an-font {
          font-weight: 300;
          letter-spacing: 6.14rem;
        }

        &.flex-div {
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 10rem;
            height: 10rem;
            background: #000;
            display: inline-block;
            padding: 0;
            margin: 0 20rem;
          }
        }

        &:nth-child(1) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }

        &:nth-child(3) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }
      }
    }
  }

  .about-gallery {
    overflow:hidden;
    position:relative;
    margin: 70vh 0 0;
    padding: 0 calc(313rem + 20rem) 0 313rem;
    box-sizing: border-box;
    height: 100%;
  }

  .scroll-wrap {
    display: flex;
    justify-content: space-between;
    // overflow-y: auto;

    .scroll-left-box {
      .scroll-item:first-child {
        margin-top: 0rem;
      }
    }

    .scroll-right-box {
      .scroll-item:first-child {
        margin-top: 380rem;
        text-align: right;
      }
    }

    .scroll-item {
      margin-bottom: 227rem;
      width: 512rem;

      &:last-child {
        margin-bottom: 0;
      }

      .img-box {
        img {
          display:block;
          width:100%;
        }
      }

      .text-box {
        .scroll-title {
          font-size: 24rem;
          padding: 0;
          margin: 0 0 16rem 0;
          font-family: $font-family-2;
        }
      }

      .scroll-desc {
        font-size: 20rem;
        padding: 0;
        margin: 0;
      }
    }
  }

  &.active {
    .about-title {
      .txt-about {
        &:nth-child(1) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .3s;
          transition-duration: 1s;
        }

        &:nth-child(2) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .35s;
          transition-duration: 1s;
        }

        &:nth-child(3) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .4s;
          transition-duration: 1s;
        }
      }
    }
  }
}

// section-character
&section-character {
  padding:160rem;
  box-sizing:border-box;
  font-family: $font-family-2;
  text-align:center;
  background-color: #F5F6F8;
  .title {
    margin:0;
    font-size: 64rem;
    line-height:1;
  }

  .img-visual {
    margin:88rem auto 0;
    width:100%;
    max-width:1196rem;
    img {
      display:block;
      width:100%;
    }
  }
  
  .box-text {
    margin: 88rem 0 0 0;
    .subtitle,
    .name {
      display:block;
    }
    .subtitle {
      font-size:48rem;
      font-weight:300;
      line-height:58rem;
    }
    .name {
      margin:12rem 0 0 0;
      color:$color-p;
      font-size:64rem;
      font-weight:700;
      line-height:77rem;
    }
    .text {
      margin:24rem 0 0 0;
      font-size:20rem;
      font-weight:300;
      line-height:30rem;
    }
  }

  .box-tab-character {
    width:100%;
    margin: 80rem 0 0 0 ;
    padding:64rem;
    box-sizing:border-box;
    background-color: $color-white;
    .ts-theme-tabbox {
      justify-content: center;
      li {
        margin:0;
        & + li {
          margin:0 0 0 40rem;
        }
      }
      button {
        display:block;
        margin:0;
        padding:0;
        font-size:20rem;
        font-weight:300;
        line-height:1;
        .img-tab-button {
          display:block;
          position:relative;
          margin:0 auto;
          border-radius: 50%;
          width:64rem;
          height:64rem;
          background-color:#F5F6F8;
          img {
            position:relative;
            z-index: 2;
            width:100%;
          }
          &:before,
          &:after {
            content:"";
            display:block;
            position:absolute;
            border-radius: 50%;
          }
          &:before {
            top:-2rem;
            left:-2rem;
            z-index:1;
            width:64rem;
            height:64rem;
            border:3rem solid $color-white;
            background-color:#F5F6F8
          }
          &:after {
            top:-4rem;
            left:-4rem;
            width:72rem;
            height:72rem;
          }
          &.tomnova{
            & + figcaption{
              width:88rem;
            }
          }
          &.miamally{
            & + figcaption{
              width:93rem;
            }
          }
          &.pieglee{
            & + figcaption{
              width:72rem;
            }
          }
          &.joyartnet{
            & + figcaption{
              width:99rem;
            }
          }
          &.lukamilo{
            & + figcaption{
              width:92rem;
            }
          }
        }
        figcaption {
          margin:12rem 0 0 0;
          font-family: $font-family-2;
        }
        &.active {
          background-color:transparent;
          color:$color-black;
          font-weight:700;
          .img-tab-button {
            &.tomnova{
              background-color:#EE9C21;
              &:before,
              &:after{
                background-color:#EE9C21;
              }
            }
            &.miamally{
              background-color:#FF80BD;
              &:before,
              &:after{
                background-color:#FF80BD;
              }
            }
            &.pieglee{
              background-color:#FF313B;
              &:before,
              &:after{
                background-color:#FF313B;
              }
            }
            &.joyartnet{
              background-color:#00A1F0;
              &:before,
              &:after{
                background-color:#00A1F0;
              }
            }
            &.lukamilo{
              background-color:#CA7AEC;
              &:before,
              &:after{
                background-color:#CA7AEC;
              }
            }
          }
        }
      }
    }
  }

  .character-tab-contents {
    display:none;
    margin:80rem 0 0 0;
    figure {
      display:block;
      margin:0 auto;
      border-radius:50%;
      width:268rem;
      height:268rem;
      img {
        width:100%;
      }
    }
    figcaption {
      margin:24rem 0 0 0;
      color:$color-black;
      font-family: $font-family-2;
      font-size:64rem;
      font-weight: 700;
      line-height:77rem;
    }
    span {
      display:block;
      margin:12rem 0 0 0;
      font-size:32rem;
      font-weight: 700;
      line-height:37rem;
    }
    &.active {
      display:block;
      &.tomnova{
        figure {
          background-color:#EE9C21;
        }
      }
      &.miamally{
        figure {
          background-color:#FF80BD;
        }
      }
      &.pieglee{
        figure {
          background-color:#FF313B;
        }
      }
      &.joyartnet{
        figure {
          background-color:#00A1F0;
        }
      }
      &.lukamilo{
        figure {
          background-color:#CA7AEC;
        }
      }
    }
  }
  
  & + .ts-theme-section-ci {
    background-color: transparent;
  }
}

// section-ci
&section-ci {
  width: 100%;
  height: 1080rem;
  background-color: #F5F6F8;

  .inner-section {
    width: 1324rem;
    margin: 0 auto;
    padding: 160rem 0;
    box-sizing: border-box;

    .ci-title {
      font-size: 64rem;
      font-family: $font-family-2;
      text-align: center;
      margin: 0 0 80rem 0;
      padding: 0;
    }

    .ci-tab-area {
      width: 100%;

      .ts-theme-tabbox {
        margin-bottom: 48rem;

        &.square-h40 {
          justify-content: center;
        }

        li {
          font-family: $font-family-2;

          a {
            padding: 8rem 16rem;
            box-sizing: border-box;
            font-weight:500;
            &.active {
              font-weight:700;
            }
          }

          &:nth-child(1) {
            a {
              width: 144rem;
            }
          }

          &:nth-child(2) {
            a {
              width: 184rem;
            }
          }

          &:nth-child(3) {
            a {
              width: 143rem;
            }
          }
        }
      }

      .ci-tab-contents {
        text-align: center;
        position:absolute;
        z-index: 0;
        opacity: 0;
        transition: opacity .5s;

        &.active {
          z-index: 1;
          opacity: 1;
          transition: opacity .5s;
        }

        .img-box {
          margin-bottom: 48rem;
        }

        .tab-desc {
          padding: 0;
          margin: 44rem 0 0 0;
          font-size: 20rem;
        }
      }
    }

    .ci-box {
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 1322rem;
        height: 357rem;
      }

      &.ci-symbol,
      &.ci-primary {
        .img-box {
          background: url(../images/sub/img_about_cl_grid.png) no-repeat center center;
          background-size: 100%;
        }
      }

      &.ci-symbol {
        img {
          display: block;
          width: 148rem;
          &+img {
            margin-left: 230rem;
          }
        }
        picture {
          & + picture {
            margin-left: 230rem;
          }
        }
      }

      &.ci-primary {
        img {
          display: block;
          margin-top:10rem;
          width: 336rem;
          & + img {
            margin-left: 146rem;
          }
        }
        picture {
          & + picture {
            margin-left: 146rem;
          }
        }
      }

      &.ci-color {
        .img-box {
          align-items: flex-start;
          padding: 63rem 0;
          border: 1rem solid #d9d9d9;
          box-sizing: border-box;
          background-color: #fff;

          .box-color {
            width: 240rem;
            text-align: left;

            .color-box {
              width: 240rem;
              height: 120rem;
            }

            .name {
              display: block;
              margin: 16rem 0 0 0;
              color: $color-black;
              font-family: $font-family-2;
              font-size: 20rem;
              font-weight: 700;
              line-height: 24rem;
              text-transform: uppercase;
            }

            .panton {
              display: block;
              margin: 16rem 0 0 0;
              color: $color-black;
              font-family: $font-family-2;
              font-size: 20rem;
              font-weight: 300;
              line-height: 24rem;

              span {
                display: block;

                &+span {
                  margin: 8rem 0 0 0;
                }
              }
            }

            &.blue {
              .color-box {
                background-color: #004A9C;
              }
            }

            &.gray {
              .color-box {
                background-color: #7E7F83;
              }
            }

            &.gold {
              .color-box {
                background-color: #997F42;
              }
            }

            &.black {
              .color-box {
                background-color: #221E1F;
              }
            }

            &+.box-color {
              margin-left: 40rem;
            }
          }
        }
      }
    }
  }
  &.bg-white {
    background-color: transparent;
  }
}