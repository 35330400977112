&error-503 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    .box-error {
        .ico-error-503 {
            display:block;
            margin: 0 auto;
            width: 253rem;
            height: 301rem;
            background: url(../images/common/ico_error_503.svg) no-repeat center center/cover;
        }
        .title {
            margin: 72rem 0 0 0;
            font-size: 32rem;
            font-weight: 700;
            line-height: 40rem;
        }
        .text {
            margin: 24rem 0 0 0;
            font-size: 20rem;
            font-weight: 300;
            line-height: 30rem;
        }
    }
}