/* skipnav */
&skipnav {
  position: relative;
  z-index: 999;
  a {
    @include flex-center;
    position: fixed;
    top: -60rem;
    left: 0;
    width: 100%;
    height: 60rem;
    background-color: $color-p;
    color: $color-white;
    font-size: 16rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    z-index: 300;
    &:focus {
      top: 0;
    }
  }
}

/***** layout *****/
&wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

&header {
  flex: none;
  position: relative;
  min-width: 1280px;
  height: 96rem;
  margin-bottom: -96rem;
  font-family: $font-family-2;
  z-index: 99;

  &.head-hide {
    display: none !important;
  }

  &.ver-white {
    .logo {
      background-image: url(../images/common/logo_white.svg);
    }

    .gnbbox {
      .gnb {
        > li {
          > a {
            span {
              color: $color-white;
            }
          }
        }
      }
    }

    .btnbox {
      .sel-lang {
        .active-lang {
          border-color: $color-white;
          color: $color-white;

          &::before {
            background-image: url(../images/common/ico_lang_down_white.svg);
          }
        }

        .list {
          border-color: $color-white;
        }
      }

      .btn-icon {
        &.btn-my {
          background-image: url(../images/common/btn_head_my_white.svg);
        }

        &.btn-login {
          background-image: url(../images/common/btn_login_white.svg);
        }

        &.btn-logout,
        &[data-redirect="/c/portal/logout"] {
          display:none;
          background-image: url(../images/common/btn_logout_white.svg);
        }

        &.btn-search {
          background-image: url(../images/common/btn_head_search_white.svg);
        }

        &.btn-allmenu {
          background-image: url(../images/common/btn_head_menu_white.svg);
        }

        &.btn-close {
          background-image: url(../images/common/ico_popup_close.svg);
        }
      }
    }
  }

  .header-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 1280px;
    height: 96rem;
    padding: 0 40rem;
    box-sizing: border-box;

    &.fix {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: $color-white;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
      transition: 0.3s;

      .logo {
        background-image: url(../images/common/logo.svg);
      }

      .gnbbox {
        .gnb {
          > li {
            > a {
              span {
                color: $color-black;
              }
            }
          }
        }
      }

      .btnbox {
        .sel-lang {
          .active-lang {
            border-color: $color-black;
            color: $color-black;

            &::before {
              background-image: url(../images/common/ico_lang_down.svg);
            }
          }

          .list {
            border-color: $color-black;
          }
        }

        .btn-icon {
          &.btn-my {
            background-image: url(../images/common/btn_head_my.svg);
          }

          &.btn-login {
            background-image: url(../images/common/btn_login.svg);
          }

          &.btn-logout,
          &[data-redirect="/c/portal/logout"] {
            display:none;
            background-image: url(../images/common/btn_logout.svg);
          }

          &.btn-search {
            background-image: url(../images/common/btn_head_search.svg);
          }

          &.btn-allmenu {
            background-image: url(../images/common/btn_head_menu.svg);
          }

          &.btn-close {
            background-image: url(../images/common/ico_popup_close.svg);
          }
        }
      }
    }

    &.hide {
      transform: translateY(-100%);
      transition: 0s;

      .gnbbox {
        .gnb {
          > li {
            > .depth {
              max-height: 0;
              transition: 0s !important;
            }
          }
        }
      }
    }

    &:hover, &.hover {
      background-color: $color-white;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
      transition: background-color 0s;

      .logo {
        background-image: url(../images/common/logo.svg);
      }

      .gnbbox {
        .gnb {
          > li {
            > a {
              span {
                color: $color-black;
              }
            }
          }
        }
      }

      .btnbox {
        .sel-lang {
          .active-lang {
            border-color: $color-black;
            color: $color-black;

            &::before {
              background-image: url(../images/common/ico_lang_down.svg);
            }
          }

          .list {
            border-color: $color-black;
          }
        }

        .btn-icon {
          &.btn-my {
            background-image: url(../images/common/btn_head_my.svg);
          }
          
          &.btn-login {
            background-image: url(../images/common/btn_login.svg);
          }

          &.btn-logout,
          &[data-redirect="/c/portal/logout"] {
            display:none;
            background-image: url(../images/common/btn_logout.svg);
          }

          &.btn-search {
            background-image: url(../images/common/btn_head_search.svg);
          }

          &.btn-allmenu {
            background-image: url(../images/common/btn_head_menu.svg);
          }

          &.btn-close {
            background-image: url(../images/common/ico_popup_close.svg);
          }
        }
      }
    }
  }

  .logo {
    display: inline-flex;
    position: relative;
    width: 120rem;
    height: 44rem;
    margin: 0;
    background: url(../images/common/logo.svg) no-repeat center/contain;
    text-indent: -9999rem;
    overflow: hidden;
    z-index: 1;

    a {
      width: 100%;
      height: 100%;
      outline-offset: -1px;
    }
  }

  .gnbbox {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .portlet {
      position: static;
    }

    .gnb {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      &::before {
        content: "";
        position: absolute;
        top: 96rem;
        left: 50%;
        bottom:0;
        border-top: 1rem solid transparent;
        width: 1920rem;
        transform: translateX(-50%);
        transition: 0.2s;
      }

      &:hover,
      &.on {
        &::before {
          border-color: #eaeaea;
          background-color: $color-white;
          box-shadow: 0 6rem 12rem rgba(0, 0, 0, 0.08);
        }

        > li {
          > .depth {
            max-height: 100vh;
            transition: max-height 0.4s ease-in-out;
          }
        }
      }

      > li {
        position: relative;

        > a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 48rem;
          color: inherit;
          text-decoration: none;

          > span {
            display: flex;
            align-items: center;
            position: relative;
            height: 96rem;
            color: $color-black;
            font-size: 20rem;
            font-weight: 700;
            transition: 0.2s;

            &::before {
              content: "";
              position: absolute;
              left: 50%;
              bottom: -1rem;
              width: 0;
              height: 4rem;
              transform: translateX(-50%);
              transition: 0.2s;
            }
          }
        }

        > .depth {
          margin: 0;
          padding: 0;
          list-style: none;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);

          > li {
            padding: 8rem;

            &:first-child {
              margin-top: 24rem;
            }

            &:last-child {
              margin-bottom: 24rem;
            }

            > a {
              display: block;
              color: $color-black;
              font-size: 18rem;
              line-height: 30rem;
              font-weight: 300;
              text-align: center;
              text-decoration: none;
              transition: 0.2s;

              &:hover {
                color: $color-p;
                font-weight: 700;
              }
            }
          }
        }

        &:hover {
          > a {
            > span {
              color: $color-p;

              &::before {
                width: 100%;
                background-color: $color-p;
              }
            }
          }
        }
      }
    }
  }

  .btnbox {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    .sel-lang {
      display: inline-flex;
      user-select: none;
      align-items: center;
      position: relative;
      z-index: 1;
      cursor: pointer;

      .active-lang {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 75rem;
        height: 32rem;
        padding: 0 36rem 0 11rem;
        border: 1rem solid $color-black;
        border-radius: 16rem;
        font-size: 14rem;
        line-height: 16rem;
        font-weight: 700;
        box-sizing: border-box;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 9rem;
          width: 16rem;
          height: 16rem;
          background: url(../images/common/ico_lang_down.svg) no-repeat center/contain;
          transform: translateY(-50%);
        }
      }

      .list {
        max-height: 0;
        position: absolute;
        top: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0 0 16rem 16rem;
        background-color: $color-white;
        list-style: none;
        overflow: hidden;

        > li {
          + li {
            margin-top: 12rem;
          }
        }

        a {
          display: flex;
          align-items: center;
          padding: 0 11rem;
          color: inherit;
          font-size: 14rem;
          line-height: 17rem;
          font-weight: 300;
          text-decoration: none;

          &.active {
            text-decoration: underline;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.on {
        .active-lang {
          border-bottom-color: transparent !important;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          &::before {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .list {
          padding: 4rem 0 8rem;
          border-width: 0 1rem 1rem 1rem;
          border-color: transparent $color-black $color-black $color-black;
          border-style: solid;
          box-sizing:border-box;
          width: 100%;
          max-width:75rem;
          max-height: 100vh;
          margin-top:-2rem;
        }
      }
    }

    .btn-icon {
      display: inline-flex;
      margin-left: 24rem;
      border: 0;
      width: 32rem;
      height: 32rem;
      @include bg-inline;
      background-color: transparent;
      cursor: pointer;

      &.btn-my {
        background-image: url(../images/common/btn_head_my.svg);
      }

      &.btn-login {
        background-image: url(../images/common/btn_login.svg);
      }

      &.btn-logout,
      &[data-redirect="/c/portal/logout"] {
        display:none;
        background-image: url(../images/common/btn_logout.svg);
      }

      &.btn-search {
        background-image: url(../images/common/btn_head_search.svg);
      }
      
      &.btn-allmenu {
        background-image: url(../images/common/btn_head_menu.svg);
      }

      &.btn-close {
        background-image: url(../images/common/ico_popup_close.svg);
      }
    }
  }

  .wrap-search {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4rem 12rem rgba(0, 0, 0, 0.1);

    .search-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 96rem;
      padding: 0 40rem;
      box-sizing: border-box;

      .logo {
        background: url(../images/common/logo.svg) no-repeat center / contain;
      }
    }

    .box-search {
      margin: 0 auto;
      padding: 32rem 0 42rem;

      width: 784rem;

      .integrated-search {
        overflow:hidden;
        position: relative;
        border-width: 0 0 4rem 0;
        border-style: solid;
        border-color: $color-black;

        [class^="btn"] {
          flex: none;
          position:absolute;
          top: 50%;
          z-index:5;
          border: 0;
          width: 32rem;
          height: 32rem;
          box-shadow: none;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 32rem 32rem;
          cursor: pointer;
          transform: translateY(-50%);
        }
        .btn-clear {
          display:block;
          right: 56rem;
          background-image: url(../images/common/btn_ico_del.svg)
        }
        .btn-search {
          right: 0;
          background-image: url(../images/common/ico_search.svg);
        }
      }

      .input-search {
        margin:0;
        padding: 29rem 100rem 26rem 0;
        border: 0;
        color: $color-black;
        font-family: $font-family-1;
        font-size: 32rem;
        font-weight: 700;

        &::-moz-input-placeholder,
        &::-webkit-input-placeholder {
          color: $color-black;
          font-family: $font-family-1;
          font-size: 32rem;
          font-weight: 700;
        }

        &::placeholder {
          color: $color-black;
          font-family: $font-family-1;
          font-size: 32rem;
          font-weight: 700;
        }

        &::-ms-clear,
        &::-ms-reveal {
          display:none;
          appearance: none;
          width: 32rem;
          height: 32rem;
          background: url(../images/common/btn_ico_del.svg) no-repeat center center/cover;
          cursor: pointer;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display:none;
          -webkit-appearance: none;
        }
        &::-webkit-search-cancel-button {
          display:none;
          -webkit-appearance: none;
          width: 32rem;
          height: 32rem;
          background: url(../images/common/btn_ico_del.svg) no-repeat center center/cover;
          cursor: pointer;
        }
      }

      .text {
        margin: 48rem 0 0;
        color: $color-p;
        font-size: 24rem;
        font-weight: 700;
        line-height: 29rem;
        text-align: center;
      }

      .wrap-chip {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 18rem 0 0 0;

        .chip {
          margin: 6rem;
          padding: 11rem 23rem;
          border: 1rem solid $color-black;
          border-radius: 26rem;
          box-shadow: none;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 20rem;
          font-weight: 300;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
    
    & > .btnbox {
      position:absolute;
      top:32rem;
      right:40rem;
    }
    .input-group {
      & > .input-group-item {
        &.input-group-append {
          margin:0;
        }
      }
    }
    &.active {
      display: block;
      z-index: 2;
    }
  }
}

&container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1280px;
  box-sizing: border-box;
}

&footer {
  flex: none;
  position: relative;
  min-width: 1280px;
  padding: 64rem;
  background: $color-black;
  color: $color-white;
  font-size: 16rem;
  line-height: 19rem;
  font-family: $font-family-2;
  box-sizing: border-box;

  .foot-top {
    @include flex-center(flex);

    .foot-menu {
      display: flex;
      margin: 16rem 0 0 0;
      padding: 0;
      list-style: none;

      a {
        display: inline-block;
        margin-right: 24rem;
        color: inherit;
        font-weight: 700;
        text-decoration: none;
      }
    }

    .foot-logo {
      width: 116rem;
      height: 40rem;
      margin: 16rem 0 0 auto;
      background: url(../images/common/footer_logo.svg) no-repeat center/contain;
      text-indent: -9999rem;
      overflow: hidden;
    }
  }

  .foot-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 160rem;

    .foot-familysite {
      margin: 0 24rem 16rem 0;

      .tit {
        font-weight: 300;
        opacity: 0.4;
      }

      .list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-top: 12rem;

          a {
            display: inline-flex;
            align-items: center;
            color: inherit;
            font-size: 32rem;
            font-weight: 700;
            line-height: 39rem;
            text-decoration: none;

            .colon {
              display: inline-flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;
              width: 5rem;
              height: 22rem;
              margin: 1rem 4rem;

              &::before,
              &::after {
                content: "";
                width: 5rem;
                height: 8rem;
                background: $color-white;
              }
            }
          }
        }
      }
    }

    .foot-con {
      margin: 0 0 16rem auto;
      text-align: right;

      // opacity: 0.4;
      .links {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-left: 16rem;

          a {
            display: inline-flex;
            color: $color-white;
            font-weight: 700;
            text-decoration: none;
          }
        }
      }

      .txt_address {
        margin: 40rem 0 0 0;
        font-weight: 300;
        opacity: 0.4;
      }

      .txt_info {
        margin: 8rem 0 0 0;
        font-weight: 300;
        opacity: 0.4;

        .ib + .ib {
          margin-left: 20rem;
        }
      }

      .copyright {
        margin: 22rem 0 0 0;
        font-size: 14rem;
        line-height: 17rem;
        font-weight: 300;
        opacity: 0.4;
      }
    }
  }
}

/* simplebar 추가 */
&body {
  overflow: hidden;
  [data-simplebar] {
    height: 100vh;
    overflow: auto;
    .simplebar-content {
      &-wrapper {
        outline: none;
      }
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
  }
}
