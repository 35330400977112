&memb-wrap {
  position: relative;
  max-width: 512rem;
  margin: 0 auto;
  border-top: 4rem solid $color-black;
  font-family: $font-family-2;

  .login-join-iconbox {
    display: flex;
    justify-content: center;
    position: relative;

    [class*="icon"] {
      width: 56rem;
      height: 56rem;
      margin: 0 12rem;
      border-radius: 50%;
      @include bg-inline;
    }
    // 애플 로그인 활성화 2024-10-24
    .icon {
      &:nth-child(3) {
        display:none;
      }
    }
    & + .ts-theme-form {
      margin-top:64rem;
    }
  }
  
  // [dev] sns 임시 가림 20240730
  .login-join-title {
    // display:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48rem 24rem;

    // [dev] sns 임시 가림 20240730
    .login-title {
      display:block;
      margin: 0;
      font-size: 24rem;
      line-height: 32rem;
      font-weight: 700;

      &+.login-txt {
        margin: 16rem 0 0;
      }
    }

    // [dev] sns 임시 가림 20240730
    .login-txt {
      display:block;
      margin: 0;
      font-size: 20rem;
      font-weight: 300;
      line-height: 30rem;
      text-align: center;
    }

    // [dev] sns 임시 가림 20240730
    &.bg-gray {
      display:flex;
      background: #f5f6f8;
      .login-title,
      .login-txt {
        display:block;
      }
    }

    .login-account {
      margin: 0 0 20rem;
      display: flex;
      width: 48rem;
      height: 48rem;
      border-radius: 16rem;
      @include bg-inline;
    }
  }

  // [dev] sns 임시 가림 20240730
  .login-txt-btns {
    // display:none;
    display: flex;
    align-items: center;
    margin: 18rem 0 0 0;

    .txtbox {
      flex: 1;

      .tit {
        margin: 0;
        font-size: 15rem;
        line-height: 18rem;
      }

      .txt {
        margin: 8rem 0 0 0;
        font-size: 14rem;
        font-weight: 300;
        color: #666;
      }
    }

    .btnbox {
      flex: block;
      display: flex;

      [class*="icon"] {
        width: 40rem;
        height: 40rem;
        margin-left: 16rem;
        border-radius: 50%;
        @include bg-inline;
      }
      // 애플 로그인 활성화 2024-10-24
      .icon {
        &:nth-child(3) {
          display:none;
        }
      }
      .kakao {
        background-image: url(../images/common/ico_memb_kakao_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_kakao_on.svg);
        }
      }
      .naver {
        background-image: url(../images/common/ico_memb_naver_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_naver_on.svg);
        }
      }

      .google {
        background-image: url(../images/common/ico_memb_google_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_google_on.svg);
        }
      }
      .apple {
        background-image: url(../images/common/ico_memb_apple_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_apple_on.svg);
        }
      }
    }
  }

  .easy-signin {
    margin: 64rem 0 0 0;
    padding: 48rem 20rem;
    box-sizing: border-box;
    text-align: center;
    background-color: #F5F6F8;
    .icon {
      display:inline-block;
      border-radius: 16rem;
      width: 48rem;
      height: 48rem;
      font-style: normal;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 48rem 48rem;
      &.naver {
        background-color: #03C75B;
      }
      &.kakao {
       background-color: #fddc3f; 
      }
      &.google {
        background-size: 25rem 25rem;
        background-color: $color-white;
      }
      &.apple {
        background-color: $color-black;
      }
    }
    .icon:nth-child(3),
    .icon:nth-child(4){
      display:none;
    }
    .text {
      margin: 20rem 0 0 0;
      font-size: 20rem;
      letter-spacing: -0.2rem; 
      line-height: 30rem;
    }
    & + .form-title {
      margin-top:48rem;
    }
  }

  .box-gray {
    padding: 24rem;
    background-color: #f5f6f8;
    /* 임시 CMS 수정 */
    & + .ts-theme-mt-16 + .txt-info-16 {
      margin-bottom:12rem;
    }
  }

  .arrow-linkbox {
    margin: 0;
    padding: 0;
    list-style: none;

    &>li {
      &>a {
        position: relative;
        display: block;
        padding: 24rem;
        border: 1rem solid #d9d9d9;
        margin-bottom: -1rem;
        font-size: 16rem;
        font-weight: 300;
        color: inherit;
        text-decoration: none;
      }

      strong {
        display: inline-block;
        margin: 0 0 0 12rem;
        color: $color-p;
      }

      .icon-arrow {
        display: inline-block;
        width: 16rem;
        height: 16rem;
        background: url(../images/common/ico_arrow_r.svg) no-repeat center/16rem;
        position: absolute;
        right: 24rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .save-find {
    display: flex;
    justify-content: space-between;
    margin-top: 16rem;

    .find-link {
      display: flex;
      padding: 0;
      margin: 0 0 0 auto;
      list-style: none;

      >li+li {
        position: relative;
        margin-left: 12rem;
        padding-left: 13rem;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1rem;
          height: 12rem;
          background: #e5e5e5;
          transform: translateY(-50%);
        }
      }

      a {
        display: block;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .tit-center-20 {
    display: flex;
    justify-content: center;
    margin: 64rem 0 40rem 0;
    color: inherit;
    font-size: 20rem;
    line-height: 24rem;
    font-weight: 300;
    text-align: center;
  }

  .txt-center-16 {
    margin: 0;
    color: #666;
    font-size: 16rem;
    font-weight: 300;
    text-align: center;
  }

  .txt-info-16 {
    margin: 12rem 0 0;
    color: #666;
    font-size: 16rem;
    font-weight: 300;

    a {
      color: $color-p;
      font-weight: 600;
    }
  }

  .list-text-info {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      margin: 0;
      padding: 0 0 0 10rem;
      line-height: 24rem;
      list-style: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 10rem;
        left: 0;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        background-color: #666;
      }

      &+li {
        margin: 12rem 0 0 0;
      }
    }
  }

  .txt-center-20 {
    display: flex;
    justify-content: center;
    margin: 48rem 0 0 0;
    color: inherit;
    font-size: 20rem;
    line-height: 24rem;
    font-weight: 300;
    font-family: $font-family-2;
    text-align: center;

    a {
      margin-left: 12rem;
      color: $color-p;
      font-weight: 600;
    }
  }

  .text-tip {
    position: relative;
    margin: 12rem 0 0;
    padding-left: 24rem;
    color: #666;
    font-size: 16rem;
    line-height: 24rem;

    &::before {
      content: "";
      display: inline-block;
      width: 16rem;
      height: 16rem;
      background: url(../images/common/ico_note.svg) no-repeat center/contain;
      position: absolute;
      top: 3rem;
      left: 0;
    }
  }

  .bd-box {
    margin-top: 32rem;
    padding: 32rem;
    border: 1rem solid #eaeaea;

    .box-title {
      margin: 0 0 20rem;
      font-size: 24rem;
      line-height: 29rem;
    }

    .box-list {
      margin: 0;
      padding: 0;

      li {
        position: relative;
        padding-left: 14rem;
        font-size: 16rem;
        font-weight: 300;
        line-height: 24rem;
        list-style: none;

        &+li {
          margin-top: 12rem;
        }

        &:before {
          content: "";
          position: absolute;
          top: 10rem;
          left: 0;
          width: 6rem;
          height: 1rem;
          background: $color-black;
        }

        &.emphasis {
          color: #FC1B1B;
          &:before {
            background-color:#FC1B1B;
          }
        }
      }
    }
  }

  // 아이디 안내
  .zindex-guide {
    position: relative;
    z-index: 700;

    &::before {
      content: "";
      border-color: #1f75ff transparent;
      border-style: solid;
      width: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.po-top {
      &::before {
        content: "";
        border-width: 14rem 10rem 0 10rem;
        top: -15rem;
      }
    }

    &.po-bottom {
      &::before {
        content: "";
        border-width: 0rem 10rem 14rem 10rem;
        bottom: -27rem;
      }
    }
  }

  .login-join-iconbox {
    .tooltip-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: absolute;
      bottom: 77rem;
      z-index: 700;
      padding: 16rem 24rem;
      box-sizing: border-box;
      width: 351rem;
      background: #1f75ff;

      p {
        padding: 0;
        margin: 0 16rem 0 0;
        color: #fff;
        font-size: 16rem;
        font-family: $font-family-1;
      }

      .close-btn {
        display: block;
        position: absolute;
        top: 16rem;
        right: 16rem;
        border-width: 0;
        box-shadow: none;
        width: 16rem;
        height: 16rem;
        background: transparent url(../images/common/btn_white_close.svg) no-repeat center/contain;
        cursor: pointer;
      }
    }
  }

  .ts-theme-form {
    .tooltip-box {
      display: flex;
      position: absolute;
      align-items: flex-start;
      top: 76rem;
      left: 50%;
      z-index: 700;
      width: 336rem;
      margin-left: 0;
      padding: 16rem 24rem;
      box-sizing: border-box;
      box-shadow: 0 6rem 18rem rgba(0,0,0,0.4);
      background: #1f75ff;
      transform: translateX(-50%);

      .tool-text {
        color: #fff;

        p {
          padding: 0;
          margin: 0 16rem 13rem 0;
          font-size: 16rem;
          font-family: $font-family-1;
        }

        a {
          color: inherit;
          font-size: 14rem;
          font-weight: 300;
          opacity: 0.8;
        }
      }

      .close-btn {
        display: block;
        position: absolute;
        top: 16rem;
        right: 16rem;
        border-width: 0;
        box-shadow: none;
        width: 16rem;
        height: 16rem;
        background: transparent url(../images/common/btn_white_close.svg) no-repeat center/contain;
        cursor: pointer;
      }
    }
  }

  .btn-phone {
    .txt {
      display: inline-block;
      padding: 0 0 0 24rem;
      background: url(../images/common/ico_login_phone.svg) no-repeat left center;
      background-size: 20px 20px;
    }
  }
  .ts-theme-btnstyle-box {
    .ts-theme-btnstyle {
      width: 250rem;
      min-width: auto;
      &.bg-t {
        border-width:3rem;
      }
    }
  }

  // 회원가입완료
  .join-finish-box {
    margin-top:-4rem;
    // padding:177rem 0 0 0;
    border-top:5rem solid $color-white;
    box-sizing:border-box;
    
    .login-finish-title {
      margin: 0;
      padding: 196rem 0 0 0;
      color:$color-black;
      font-family: $font-family-2;
      font-size:48rem;
      font-weight:700;
      line-height:57rem;
      text-align:center;
      background: url(../images/common/img_join_finish.png) no-repeat top center;
      background-size: 152rem;
    }
    .login-finish-text {
      margin:24rem 0 0 0;
      color:$color-black;
      font-family: $font-family-2;
      font-size: 20rem;
      font-weight:300;
      line-height:24rem;
      text-align:center;
    }
    .box-gray {
      margin-top:48rem;
      padding: 48rem 24rem;
      width:512rem;
      box-sizing:border-box;
      text-align:center;
      p {
        margin:0;
        padding:0;
        font-size:20rem;
        line-height:30rem;
      }
      .list-app-download {
        display:flex;
        align-items:center;
        justify-content: center;
        margin:32rem 0 0;
        padding:0;
        li {
          width:100rem;
          list-style: none;
          img {
            display:block;
            width:100%;
          }
          .link {
            display:block;
            margin:8rem 0 0;
          }
          & + li {
            margin-left:16rem;  
          }
        }
      }
    }
    .ts-theme-btnstyle-box {
      margin-top:64rem;
      .ts-theme-btnstyle {
        width: 250rem;
        min-width: auto;
        &.bg-t {
          border-width:3rem;
        }
      }
    }
  }
}