&main {
  height: 100vh;
  overflow: auto hidden !important;
  .ts-theme-main-container {
    min-width: 1280px;
  }

  .section {
    outline: none;
    &:not(.section-horizontal) {
      overflow: hidden;
      position: relative;
      height: 100vh;
    }
    .inner {
      width: 1600rem;
      margin: auto;
    }
  }

  .scroll.scroll-lock {
    width: 100% !important;
    overflow: hidden !important;
  }

  .tit-h2 {
    margin: 0;
    font-size: 80rem;
    line-height: 96rem;
    font-weight: 700;
    font-family: $font-family-2;
  }

  .card-content {
    display: block;
    text-decoration: none;
    .imgbox {
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 100%;

      img {
        display: block;
        width: 100%;
        transform: scale(1);
        transition: transform 0.3s;
      }
    }
    .detail {
      padding: 16rem 0 0 0;
      box-sizing: border-box;
      .title {
        margin: 0;
        color: $color-black;
        font-size: 20rem;
        font-weight: 700;
        font-family: $font-family-2;
      }
      .infomation {
        display: flex;
        align-items: center;
        margin: 8rem 0 0 0;
        color: #999;
        font-family: $font-family-2;
        font-size: 16rem;
        line-height: 1;
        .location {
          display: block;
          padding: 0 8rem 0 0;
        }
        .date {
          display: block;
          position: relative;
          padding: 0 0 0 9rem;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 1rem;
            height: 8rem;
            background-color: #999;
            transform: translateY(-50%);
          }
        }
        .magazine {
          display: block;
          width: 100%;
          color: $color-black;
          font-size: 20rem;
        }
      }
    }
    &.giant {
      width: 648rem;
      padding-top: 3vh;
      text-align: center;
      .imgbox {
        max-height:480rem;
      }
      .title {
        font-size: 40rem;
      }
      &:hover,
      &:focus {
        .imgbox {
          img {
            transform: scale(1);
          }
        }
      }
    }
    &:hover,
    &:focus {
      .imgbox {
        img {
          transform: scale(1.2);
          transition: transform 0.3s;
        }
      }
    }
  }
  /* section-main */
  .section-main {
    .main-bg {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      transition: all 0.65s;
    }
    .main-tit {
      display: block;
      position: absolute;
      left: 40rem;
      top: 50%;
      z-index: 2;
      color: $color-white;
      font-family: $font-family-2;
      font-size: 14rem;
      font-weight: 500;
      transform: translateY(-50%) rotate(90deg);
      pointer-events: none;
    }
    .swiper-main {
      height: 100vh;
      .paging-box {
        position: absolute;
        right: 32rem;
        top: 50%;
        height: 167rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transform: translateY(-50%);
        span {
          font-weight: bold;
          color: #fff;
        }
      }
      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        opacity: 0.2;
        filter: blur(10px);
        transition: 0.5s;
        .visual {
          display:block;
          width: 1050rem;
          color: $color-white;
          font-family: $font-family-1;
          font-size: 24rem;
          font-weight: 500;
          text-decoration: none;
          transition: all 0.3s;
          .txt,
          .date {
            padding: 0 0 0 28rem;
            font-size: 18rem;
            line-height: 1;
          }
          .txt {
            margin: 0 0 24rem;
            font-family: $font-family-2;
          }
          .date {
            display: block;
            margin: 24rem 0 0;
            font-family: $font-family-2;
          }
          .img-box {
            position: relative;
            margin:0 0 0 -137rem;
            padding-left:137rem;
            width:100%;
            max-width:1194rem;
            .ts-title {
              position:absolute;
              top:46%;
              left:0;
              margin:0;
              padding:0;
              color:$color-white;
              font-family:$font-family-2;
              font-size: 120rem;
              font-weight: 500;
              line-height: 136rem;
              transform:translateY(-50%);
              p {
                margin:0;
                padding:0;
              }
            }
            img {
              display: block;
              width: 100%;
            }
          }
        }
        &-active {
          opacity: 1;
          filter: blur(0px);
        }
      }
      .swiper-pagination-progressbar {
        width: 1rem;
        height: 100rem;
        top: 50%;
        left: auto;
        right: 40rem;
        transform: translateY(-50%);
        .swiper-pagination-progressbar-fill {
          background: #fff;
        }
      }
    }
    .scroll-bar {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      z-index: 9;
      & > span {
        position: relative;
        display: block;
        width: 1rem;
        height: 60rem;
        background: rgba(255, 255, 255, 0.16);
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          display: block;
          width: 11rem;
          height: 11rem;
          box-sizing: border-box;
          border: 1rem solid #fff;
          border-radius: 50%;
          animation: moveIcon 0.5s infinite alternate;
        }
      }
    }
    @keyframes moveIcon {
      0% {
        transform: translate(-50%, -10rem);
      }
      100% {
        transform: translate(-50%, -25rem);
      }
    }
  }

  /* section-news */
  .section-news {
    background: #fff;
    .fp-tableCell {
      vertical-align:top;
    }
    .tit-h2 {
      margin-top: 66rem;
      opacity: 0;
      transform: translateY(80rem);
      transition: 0.2s;
    }
    .swiper-news {
      padding-top: 6vh;
      display: flex;
      width: 0;
      min-width: 1600rem;
      margin: 0 auto;
      opacity: 0;
      transform: translateY(80rem);
      transition: 0.2s;
      .swiper-wrapper {
        display: flex;
        margin: 0 -16rem;
        transition-timing-function: linear;
      }
      .swiper-slide {
        width:auto;
        margin: 0 16rem;
        
        .card-content {
          width:376rem;
          &.wide {
            width: auto;
            max-width: 878rem;
          }
          .imgbox {
            max-height: 480rem;
          }
        }
      }
    }
    &.active {
      .tit-h2 {
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s 0.5s;
      }
      .swiper-news {
        opacity: 1;
        transform: translateY(0);
        transition: 0.5s 0.8s;
      }
    }
  }

  /* section-maga */
  .flex-maga-wrap {
    overflow: hidden;
  }
  .flex-maga {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% + var(--scrollbar-width));
    height: 100vh;
    padding: 0 160rem;
    overflow: auto;
    box-sizing: border-box;
    .pin-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      position: sticky;
      top: 0;
    }
    .move-box {
      overflow: hidden;
      .box {
        display: flex;
        .imgbox {
          max-height:501rem;
        }
      }
    }
  }
  .section-maga {
    background-color: #f5f6f8;
    .move-box {
      .left-list {
        &-wrap {
          display: flex;
        }
        width: 376rem;
        padding-bottom: 120rem;
        // transition: .5s linear;
      }
      .right-list {
        &-wrap {
          display: flex;
        }
        width: 376rem;
        margin-top: 120rem;
        margin-left: 32rem;
        padding-bottom: 120rem;
        // transition: .5s linear;
      }
      .item {
        margin: 100rem 0;
      }
    }
    .flex-maga {
      .tit-h2 {
        opacity: 0;
        transform: translateY(80rem);
        transition: 0.2s;
      }
      .card-content {
        opacity: 0;
        transform: translateY(80rem);
        transition: 0.2s;
      }
      .move-box {
        .left-list-wrap {
          opacity: 0;
          transform: translateY(80rem);
          transition: 0.2s;
        }
        .right-list-wrap {
          opacity: 0;
          transform: translateY(80rem);
          transition: 0.2s;
        }
      }
    }
    &.active {
      .flex-maga {
        .tit-h2 {
          opacity: 1;
          transform: translateY(0);
          transition: 0.5s 0.5s;
        }
        .card-content {
          opacity: 1;
          transform: translateY(0);
          transition: 0.5s 0.8s;
        }
        .move-box {
          .left-list-wrap {
            opacity: 1;
            transform: translateY(0);
            transition: 0.5s 1.1s;
          }
          .right-list-wrap {
            opacity: 1;
            transform: translateY(0);
            transition: 0.5s 1.4s;
          }
        }
      }
    }
  }

  /* section-art */
  .section-art {
    background: #171717 !important;
    .tit-h2 {
      position: absolute;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);
      margin-top: 0;
      transition: all 0.5s ease;
      color: $color-white;
      text-align: center;
    }
    .fp-scrollable {
      position: static;
      .fp-scroller {
        overflow: visible;
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20rem;
      width: 230rem;
      height: 320rem;
      box-sizing: border-box;
      color: $color-white;
      font-family: $font-family-2;
      text-decoration: none;
      b {
        font-size: 19rem;
        font-weight: 300;
      }
      h3 {
        margin: 0 0 4rem 0;
      }
      p {
        margin: 0;
      }
      &.blue {
        background-color: #0069de;
      }
      &.black {
        background-color: #404040;
      }
      &.orange {
        background-color: #e87500;
      }
    }
    &.action {
      .tit-h2 {
        padding-bottom: 45vh;
      }
      .card-left {
        .card-item {
          &.card1 {
            transform: translate(37.25vw, 50vh) rotate(360deg);
          }
          &.card2 {
            transform: translate(-100vw, -100vh) rotate(270deg);
          }
          &.card3 {
            transform: translate(23.75vw, 45vh) rotate(0deg);
          }
          &.card4 {
            transform: translate(-100vw, -100vh) rotate(29deg);
          }
        }
      }
      .card-right {
        .card-item {
          &.card1 {
            transform: translate(100vw, 27.57rem) rotate(-120deg);
          }
          &.card2 {
            transform: translate(0.75vw, 45vh) rotate(360deg);
          }
          &.card3 {
            transform: translate(100vw, 100vh) rotate(-24deg) scale(1, 1);
          }
          &.card4 {
            transform: translate(14.25vw, 50vh) rotate(0);
          }
        }
      }
    }
  }

  /* section-card */
  .card-box {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    .card-item {
      position: absolute;
      width: 229.5rem;
      transition: all 0.5s ease;
      img {
        width: 100%;
      }
    }
  }
  .card-left,
  .card-right {
    width: 50%;
    position: relative;
  }
  .card-left {
    .card-item {
      &.card1 {
        transform: translate(17vw, -11vh) rotate(134deg);
      }
      &.card2 {
        transform: translate(8vw, 11vh) rotate(18deg);
      }
      &.card3 {
        transform: translate(15vw, 56vh) rotate(-35deg);
      }
      &.card4 {
        transform: translate(0vw, 85vh) rotate(29deg);
      }
    }
  }
  .card-right {
    .card-item {
      &.card1 {
        transform: translate(15vw, 3vh) rotate(15deg);
      }
      &.card2 {
        transform: translate(29vw, 15vh) rotate(-33deg);
      }
      &.card3 {
        transform: translate(27vw, 68vh) rotate(-24deg);
      }
      &.card4 {
        transform: translate(14vw, 78vh) rotate(12deg);
      }
    }
  }

  /* section-about */
  .section-about {
    .tit-h2 {
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 20;
      transform: translate(-50%, -50%);
      pointer-events: none;
      mix-blend-mode: color-burn;
      .txt-about {
        &:nth-child(1) {
          transform: translateX(50%);
        }
        &:nth-child(2) {
          transform: translateX(-50%);
          text-align: right;
        }
        &:nth-child(3) {
          transform: translateX(50%);
        }
        &:nth-child(4) {
          transform: translateX(50%);
        }
      }
      .txt-about {
        font-family: $font-family-2;
        font-size: 80rem;
        line-height: 96rem;
        color: #222;
        font-weight: 700;
        transition: all 0.5s ease;
        opacity: 0;
        + .txt-about {
          margin-top: 15rem;
        }
      }
      .txt {
        margin-top: 16rem;
        opacity: 0;
        transition: all 0.5s ease;
        color: #222;
        font-family: $font-family-1;
        font-size: 20rem;
        line-height: 30rem;
        font-weight: 500;
        text-align: right;
        transform: translateX(50%);
      }
    }
    .about-gallery {
      overflow: hidden;
      position: relative;
      height: 100vh;
      margin: auto;
      transition: all 0.3s ease;
      opacity: 0;
      .scroll {
        overflow: auto;
        position: relative;
        z-index: 10;
        width: calc(100% + var(--scrollbar-width));
        height: 100%;
        padding: 0 313rem;
        box-sizing: border-box;
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > div {
          width: #{"min(50%, 512rem)"};
        }
      }
      .card-content {
        .txt {
          font-family: $font-family-1;
          font-size: 16rem;
          font-weight: 300;
          line-height: 1.5;
        }
        &:hover,
        &:focus {
          .imgbox {
            img {
              transform: scale(1);
            }
          }
        }
        .detail {
          h3 {
            margin:0;
            color:$color-black;
            font-family: $font-family-2;
            font-size:20rem;
            font-weight:700;
          }
          p{
            display:flex;
            align-items:center;
            margin:8rem 0 0 0;
            color:#999;
            font-family: $font-family-2;
            font-size:16rem;
            font-weight:300;
            line-height:1.5;
          }
        }
      }
      .about-left {
        left: 0;
        .item {
          &.item {
            &:nth-child(1) {
              margin-top: 1080rem;
            }
            &:nth-child(2) {
              margin-top: 1115rem;
              margin-bottom: 120rem;
            }
          }
        }
      }
      .about-right {
        right: 0;
        .item {
          &:first-child {
            margin-top: 1900rem;
          }
        }
      }
    }
    &.active {
      .tit-h2 {
        .txt-about {
          &:nth-child(1) {
            transform: translateX(-20%);
            opacity: 1;
            transition-delay: 0.5s;
          }
          &:nth-child(2) {
            transform: translateX(30%);
            opacity: 1;
            transition-delay: 0.55s;
          }
          &:nth-child(3) {
            transform: translateX(-30%);
            opacity: 1;
            transition-delay: 0.6s;
          }
          &:nth-child(4) {
            transform: translateX(20%);
            opacity: 1;
            transition-delay: 0.65s;
          }
        }
        .txt {
          transform: translateX(-8%);
          opacity: 1;
          transition-delay: 0.65s;
        }
      }
      .about-gallery {
        opacity: 1;
        transition: all 0.6s ease;
      }
    }
  }

  /* FOOD & BEVERAGE */
  .section-horizontal {
    .inner-scroll-area {
      overflow: auto;
      height: 100%;
    }
  }
  .horizontal-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    .relative-holder {
      position: relative;
    }
  }
  .pin-wrap {
    position: relative;
    z-index: 3;
  }
  .food-bev {
    height: 100vh;
    overflow: hidden;
    display: inline-block;
    z-index: 1;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .img-box {
      width: 100%;
      margin: auto;
      height: 100vh;
      animation: none;
      transform: translateX(-100vw) scale(0.5);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .txt-box {
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 700;
      color: #fff;
      text-align: center;
      animation: none;
      transform: translate(100vw, -50%);
      h2 {
        margin: 0;
        font-family: $font-family-2;
        font-size: 80rem;
        line-height: 96rem;
        text-transform: uppercase;
        // width: max-content;
      }
      p {
        margin-top: 20rem;
        font-family: $font-family-1;
        font-size: 20rem;
        line-height: 30rem;
      }
    }
  }

  .section:not(.active) {
    .food-bev {
      .img-box {
        animation: notFbImgMove 0.5s linear forwards;
      }
      .txt-box {
        animation: notFbTxtMove 0.5s linear forwards;
      }
    }
  }

  .section-eat {
    .fp-tableCell {
      vertical-align: top;
    }
    .food-cont {
      .title {
        transform: translateX(-100vw);
        animation: notFcTxtMove 0.5s linear forwards;
      }
      .tab-cont-wp {
        transform: translateX(100vw);
        transition: 0.5s;
      }
      .tab-cont {
        height: 0;
        opacity: 0;
        visibility: hidden;
        // animation: notFcImgMove 0.5s linear forwards;
        &.active {
          height: auto;
          opacity: 1;
          visibility: visible;
        }
        .swiper-slide {
          &:nth-child(1) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s;
          }
          &:nth-child(2) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s;
          }
          &:nth-child(3) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s;
          }
        }
      }
    }
  }
  .section.active {
    .food-bev {
      .img-box {
        animation: fbImgMove 1s linear 0.5s forwards;
      }
      .txt-box {
        animation: fbTxtMove 1.5s linear 0.5s forwards;
      }
    }

    .food-cont {
      .title {
        animation: fcTxtMove 1s linear 0s forwards;
      }
      .tab-cont-wp {
        overflow:hidden;
        transform: translateX(0);
        transition: 0s;
      }
      .tab-cont {
        // animation: fcImgMove 0.5s linear 0.5s forwards;
        .swiper-slide {
          padding-bottom: 28rem;
          
          &:nth-child(1) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s 0.2s;
          }
          &:nth-child(2) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s 0.4s;
          }
          &:nth-child(3) {
            opacity: 0;
            transform: translateX(120rem);
            transition: 0.5s 0.6s;
          }
        }
        &.active {
          .swiper-slide {
            &:nth-child(1) {
              opacity: 1;
              transform: translateX(0);
            }
            &:nth-child(2) {
              opacity: 1;
              transform: translateX(0);
            }
            &:nth-child(3) {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
    &.first-load {
      .food-cont {
        .tab-cont {
          &.active {
            .swiper-slide {
              &:nth-child(1) {
                transition: 0.5s 0.8s;
              }
              &:nth-child(2) {
                transition: 0.5s 1s;
              }
              &:nth-child(3) {
                transition: 0.5s 1.2s;
              }
            }
          }
        }
      }
    }
  }

  // fb
  @keyframes fbImgMove {
    0% {
      transform: translateX(-100vw) scale(0.5);
    }
    25% {
      transform: translateX(-50vw) scale(0.5);
    }
    50% {
      transform: translateX(0) scale(0.5);
    }
    75% {
      transform: translateX(0) scale(0.75);
    }
    100% {
      transform: translateX(0) scale(1);
    }
  }
  @keyframes fbTxtMove {
    0% {
      transform: translate(100vw, -50%);
    }
    25% {
      transform: translate(100vw, -50%);
    }
    50% {
      transform: translate(100vw, -50%);
    }
    75% {
      transform: translate(50vw, -50%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  @keyframes notFbImgMove {
    0% {
      transform: translateX(0) scale(1);
    }
    25% {
      transform: translateX(0) scale(0.75);
    }
    50% {
      transform: translateX(0) scale(0.5);
    }
    75% {
      transform: translateX(-50vw) scale(0.5);
    }
    100% {
      transform: translateX(-100vw) scale(0.5);
    }
  }
  @keyframes notFbTxtMove {
    0% {
      transform: translate(-50%, -50%);
    }
    25% {
      transform: translate(50vw, -50%);
    }
    50% {
      transform: translate(100vw, -50%);
    }
    75% {
      transform: translate(100vw, -50%);
    }
    100% {
      transform: translate(100vw, -50%);
    }
  }

  // fc
  @keyframes fcImgMove {
    0% {
      transform: translateX(100vw);
    }
    // 25% {
    //   transform: translateX(100vw);
    // }
    // 50% {
    //   transform: translateX(100vw);
    // }
    // 75% {
    //   transform: translateX(50vw);
    // }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes fcTxtMove {
    0% {
      transform: translateX(-100vw);
    }
    // 25% {
    //   transform: translateX(-100vw);
    // }
    // 50% {
    //   transform: translateX(-100vw);
    // }
    // 75% {
    //   transform: translateX(-50vw);
    // }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes notFcImgMove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100vw);
    }
  }
  @keyframes notFcTxtMove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  .horizon-scroll {
    background-color: #fff;
  }
  .food-cont {
    display: flex;
    margin: 156rem 0 0;
    padding: 0 0 0 160rem;
    // width: 1600rem;
    width: 100%;
    position: relative;
    .title {
      position: relative;
      flex-shrink: 0;
      width: 530rem;
      // transform: translateX(-100vw);
      z-index: 5;
      h2 {
        margin-top: 0;
        font-family: $font-family-2;
        font-size: 80rem;
      }
    }
  }
  .tab-menu {
    margin: 76rem 0 0 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: block;
        font-family: $font-family-2;
        font-size: 28rem;
        font-weight: 500;
        color: #999;
        text-transform: uppercase;
        text-decoration: none;
      }
      &.active {
        a {
          color: #000;
        }
      }
      & + li {
        margin-top: 24rem;
      }
      .num {
        vertical-align: super;
        font-size: 14rem;
      }
    }
  }
  .swiper-food {
    position: relative;
    margin-left:-423rem;
    width:100%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      bottom: -28rem;
      width: 9999rem;
      background: #fff;
      z-index: 2;
    }
  }
  .restaurant-card {
    position: relative;
    margin: 0 0 0 17rem;
    padding: 0;
    width: 512rem;
    text-decoration:none;
    .badge {
      display: block;
      position: absolute;
      top: 16rem;
      left: -17rem;
      z-index: 2;
      margin:0;
      padding: 8rem 14rem;
      border-radius:0;
      color: $color-white;
      font-family: $font-family-2;
      font-weight: 500;
      font-size: 16rem;
      line-height: 1;
      background-color: $color-black;
    }
    .imgbox {
      overflow: hidden;
      margin: 0;
      padding: 0;
      img {
        width: 100%;
        vertical-align: top;
        transform: scale(1);
        transition: transform 0.3s;
      }
    }
    .txt {
      margin-top:24rem;
      padding-left:32rem;
      color: $color-black;
      font-family: $font-family-2;
      font-weight: 700;
      .txt-floor {
        display: block;
        font-size: 26rem;
        line-height:31rem;
      }
      .restaurant {
        margin: 8rem 0 0 0;
        font-size: 40rem;
        line-height: 46rem;
      }
    }
    &:hover,
    &:focus {
      .imgbox {
        img {
          transform: scale(1.2);
          transition: transform 0.3s;
        }
      }
    }
  }
  .quick-menu {
    position: fixed;
    top: 452rem;
    right: 24rem;
    width: 81rem;
    z-index: 4;
    transition: 0.1s ease-in all;
    &.hide {
      opacity: 0;
    }
  }

  #sectionFloor {
    .fp-tableCell {
      vertical-align:top;
    }
  }
  .business_wrap {
    overflow: hidden;
    position: relative;
    margin-top: 200rem;
  }

  .family_list {
    display: flex;
    flex-direction: column;
    margin: 50vh auto;
    padding: 0;
    position: relative;
    z-index: 4;
    width: 1600rem;
    margin: 50vh auto;
    .list {
      display: flex;
      align-items: center;
      padding: 50rem 0;
      border-bottom: 1rem solid rgba(255, 255, 255, 0.16);
      text-decoration:none;
      cursor: default;

      .left {
        display: flex;
        align-items: center;
      }
      .type {
        margin: 0;
        width: 175rem;
        font-family: $font-family-2;
        font-size: 64rem;
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke: 1rem rgba(255, 255, 255, 0.32);
      }
      .txt {
        font-family: $font-family-1;
        font-size: 20rem;
        font-weight: 700;
        color: #fff;
        opacity: 0.32;
      }
      &.on {
        .type {
          color: #fff;
          -webkit-text-stroke: transparent;
        }
        .txt {
          opacity: 1;
        }
      }
    }
  }
  .bg-list-div {
    pointer-events: none;
    .item {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: 1s linear;
      &.active {
        opacity: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
  }
  .last-box {
    overflow-y: auto;
    width: calc(100% + var(--scrollbar-width));
    height: 100vh;
    background-color: #000;
    box-sizing: border-box;
    .business_wrap {
      margin-top: 0;
    }
  }
  .btn-up {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65rem;
    height: 65rem;
    position: fixed;
    right: 64rem;
    bottom: 64rem;
    border-radius: 50%;
    color: $color-white;
    font-family: $font-family-2;
    font-weight: 500;
    text-decoration: none;
    transition: opacity 0.3s;
    background-color: $color-black;
    opacity: 0;
    i {
      position: relative;
      display: block;
      margin: 4rem 0 0 0;
      padding-top: 12rem;
      font-style: normal;
      line-height: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 10rem;
        height: 10rem;
        box-sizing: border-box;
        border-width: 4rem 4rem 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-45deg);
      }
    }
    &.on {
      opacity: 1;
    }
    &.reverse-color {
      color: $color-black;
      background-color: $color-white;
      transition:all 0.3s;
      i {
        &::before {
          border-color: $color-black;
        }
      }
    }
  }
}
