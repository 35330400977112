&facilities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32rem;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family:$font-family-2;

  > li {
    flex: 0 0 calc(100% / 3 - 64rem / 3);
    transition-delay: 0s;

    &.aos-init:nth-child(3n + 2) {
      transition-delay: 0.3s;
    }

    &.aos-init:nth-child(3n + 3) {
      transition-delay: 0.6s;
    }

    .inbox {
      display: block;
      padding: 40rem;
      border: 1rem solid #eaeaea;
      box-sizing: border-box;
      height: 444rem;
      color: inherit;
      text-decoration: none;

      .icon {
        width: 80rem;
        height: 80rem;

        >svg {
          width: inherit;
          height: inherit;
        }
      }

      .tit-floor {
        display: flex;
        margin-top: 32rem;

        .tit {
          margin: 0;
          font-size: 32rem;
          line-height: 38rem;
          font-weight: 700;
        }

        .floor {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          margin: 0 0 0 16rem;
          padding: 5rem 0;
          list-style: none;

          >li {
            margin-right: 4rem;
            a {
              display:inline-block;
              padding: 0 10rem;
              border: 1rem solid $color-black;
              border-radius: 16rem;
              color: $color-black;
              font-size: 16rem;
              line-height: 26rem;
              font-weight: 700;
              text-decoration: none;
              cursor:default; // 임시
            }
          }
        }
      }

      .dl-info {
        margin-top: 32rem;

        >dl {
          display: flex;
          margin: 0;
          font-size: 20rem;
          letter-spacing: -0.016em;

          &+dl {
            margin-top: 25rem;
          }

          >dt {
            flex: none;
            display: flex;
            width: 148rem;
            padding-right: 12rem;
            line-height: 24rem;
            font-weight: 600;

            [class*="ico-"] {
              width: 24rem;
              height: 24rem;
              margin-right: 8rem;
              @include bg-inline;
            }

            // .ico-tel {
            //   background-color: red;
            // }
            // .ico-time {
            //   background-color: greenyellow;
            // }
            // .ico-location {
            //   background-color: skyblue;
            // }
          }

          >dd {
            margin: -3rem 0;
            line-height: 30rem;
            font-weight: 300;
            font-family: $font-family-2;

            &.kr-font {
              font-family: $font-family-1;
            }

            span {
              color: #666666;
              font-size: 16rem;
              font-family: $font-family-1;
            }

            p {
              margin: 0;

              &+p {
                margin-top: 12rem;
              }
            }
          }
        }
      }
    }

    // svg
    .icon svg path {
      stroke: $color-white;
      transition: all 1.5s linear;
    }

    &.svg01 {
      path {
        stroke-width: 4;
        stroke-dasharray: 332;
      }
    }

    &.svg02 {
      path {
        stroke-width: 4;
        stroke-dasharray: 212;
      }
    }

    &.svg03 {
      path {
        stroke-width: 4;
        stroke-dasharray: 196;
      }
    }

    &.svg04 {
      path {
        stroke-width: 8;
        stroke-dasharray: 290;
      }
    }

    &.svg05 {
      path {
        stroke-width: 5;
        stroke-dasharray: 256;
      }
    }

    &.svg06 {
      path {
        stroke-width: 4;
        stroke-dasharray: 128;
      }
    }

    &.svg07 {
      path {
        stroke-width: 4;
        stroke-dasharray: 99;
      }
    }

    &.svg08 {
      path {
        stroke-width: 8;
        stroke-dasharray: 220;
      }
    }

    &.svg09 {
      path {
        stroke-width: 4;
        stroke-dasharray: 228;
      }
    }

    &.svg10 {
      path {
        stroke-width: 4;
        stroke-dasharray: 130;
      }
    }

    &.svg11 {
      path {
        stroke-width: 4;
        stroke-dasharray: 216;
      }
    }

    &.aos-animate {
      &.svg01 {
        path {
          stroke-dashoffset: 332;
        }
      }

      &.svg02 {
        path {
          stroke-dashoffset: 212;
        }
      }

      &.svg03 {
        path {
          stroke-dashoffset: 196;
        }
      }

      &.svg04 {
        path {
          stroke-dashoffset: 290;
        }
      }

      &.svg05 {
        path {
          stroke-dashoffset: 256;
        }
      }

      &.svg06 {
        path {
          stroke-dashoffset: 128;
        }
      }

      &.svg07 {
        path {
          stroke-dashoffset: 99;
        }
      }

      &.svg08 {
        path {
          stroke-dashoffset: 220;
        }
      }

      &.svg09 {
        path {
          stroke-dashoffset: 228;
        }
      }

      &.svg10 {
        path {
          stroke-dashoffset: 130;
        }
      }

      &.svg11 {
        path {
          stroke-dashoffset: 216;
        }
      }
    }
  }
}