&subpage-content{
  &.parking {
    .ts-theme-js-tabwrap {
      position: relative;
      min-height:1125rem;
    }
    .ts-theme-tabbox {
      > li {
        > a {
          font-family: $font-family-2;
          font-weight: 300;
          &.active {
            font-weight: 700;
          }
        }
      }
    }
    .ts-theme-js-tabcon {
      display:block;
      position: absolute;
      z-index:0;
      opacity:0;
      transition: opacity .5s;
      &.active {
        z-index: 1;
        opacity:1;
        transition: opacity .5s;
      }
    }
  }
}
&parking-imgbox {
  overflow: hidden;
  position: relative;
  margin-top: 64rem;
  border: 1rem solid $color-black;
  box-sizing:border-box;
  width: 100%;
  max-width: 1600rem;
  height: auto;
  background-color: $color-white;
  img {
    width: 100%;
    vertical-align: top;
  }
}
&parking-txtbox {
  display: flex;
  justify-content: space-between;
  margin-top: 64rem;
  width: 100%;
  max-width: 1600rem;
  font-family: $font-family-2;
  [class$="box"] {
    flex-shrink: 0;
    // width: 50%;
  }
  .left-box {
    width: 383rem;
  }
  .middle-box {
    width: 595rem;
  }
  .right-box {
    &.fix {
      width: 489rem;
    }
  }
  .info-tit {
    margin: 0;
    font-family: $font-family-2;
    font-weight: 700;
    font-size: 32rem;
  }
  ul {
    margin: 24rem 0 0;
    padding: 0;
    & + .info-tit {
      margin-top: 64rem;
    }
  }
  .info-txt {
    margin-bottom: 16rem;
    padding-left: 14rem;
    position: relative;
    list-style: none;
    font-size: 20rem;
  }
  .info-txt::before {
    content: "";
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: #000;
    top: 11rem;
    left: 0;
    transform: rotate(45deg);
  }
}
