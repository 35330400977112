&container {
  .inner-title-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      font-size: 64rem;
      margin: 0;
      padding: 0;
    }

    .desc {
      font-size: 32rem;
      margin: 0;
      padding: 0;
      font-weight: 500;
      line-height: 44rem;

      .color-b {
        color: #1E75FF;
        font-weight: 700;
      }
    }
  }

  .box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1rem solid #EAEAEA;
    background-color: #fff;

    .icon {
      display: block;
      width: 88rem;
      height: 88rem;
      font-style: normal;

      svg {
        width:100%;
        height:100%;
        path,
        rect,
        line,
        circle {
          stroke: white;
          stroke-width: 2;
          transition: all 1.5s linear;
        }
      }

      &.svg01 {
        path {
          stroke: $color-black;
          stroke-dasharray: 232;
          stroke-dashoffset: 232;
        }
      }

      &.svg02 {
        path {
          stroke: $color-black;
          stroke-dasharray: 168;
          stroke-dashoffset: 168;
        }
      }

      &.svg03 {
        path {
          stroke: $color-black;
          stroke-dasharray: 288;
          stroke-dashoffset: 288;
        }
      }

      &.svg04 {
        path {
          stroke: $color-black;
          stroke-dasharray: 283;
          stroke-dashoffset: 283;
        }
      }

      &.svg05 {
        path {
          stroke: $color-black;
          stroke-dasharray: 288;
          stroke-dashoffset: 288;
        }
      }

      &.svg06 {
        path {
          stroke: $color-black;
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
        }
      }

      &.svg07 {
        path {
          stroke: $color-black;
          stroke-dasharray: 163;
          stroke-dashoffset: 163;
        }

        circle {
          stroke: $color-black;
          stroke-dasharray: 82;
          stroke-dashoffset: 82;
        }

        rect {
          stroke: $color-black;
          stroke-dasharray: 188;
          stroke-dashoffset: 188;
        }

        line {
          stroke: $color-black;
          stroke-dasharray: 26;
          stroke-dashoffset: 26;
        }
      }

      &.svg08 {
        path {
          stroke: $color-black;
          stroke-dasharray: 222;
          stroke-dashoffset: 222;
        }

        line {
          stroke: $color-black;
          stroke-dasharray: 20;
          stroke-dashoffset: 20;
        }
      }

      &.svg09 {
        path {
          stroke: $color-black;
          stroke-dasharray: 225;
          stroke-dashoffset: 225;
        }
      }
    }

    .logo {
      width: 176rem;

      img {
        display: block;
        width: 100%;
      }

      &+.desc {
        margin: 32rem 0 0 0;
      }
    }

    .title {
      display: block;
      margin: 48rem 0 0 0;
      padding: 0;
      font-family: $font-family-2;
      font-size: 24rem;
      font-weight: 700;
    }

    .desc {
      font-size: 20rem;
      font-weight: 300;
      padding: 0;
      margin: 0;
    }

    &.aos-animate {
      .svg01 {
        path {
          stroke-dashoffset: 0;
        }

        rect {
          stroke: #000;
          stroke-dashoffset: 0;
        }
      }

      .svg02 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg03 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg04 {
        path {
          stroke-dashoffset: 0;
        }

        rect {
          stroke-dashoffset: 88;
        }
      }

      .svg05 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg06 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg07 {
        path {
          stroke-dashoffset: 0;
        }

        circle {
          stroke-dashoffset: 0;
        }

        rect {
          stroke-dashoffset: 0;
        }

        line {
          stroke-dashoffset: 0;
        }
      }

      .svg08 {
        path {
          stroke-dashoffset: 0;
        }

        line {
          stroke-dashoffset: 0;
        }
      }

      .svg09 {
        path {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  .ts-theme-develope {
    background: #F5F6F8;

    .inner-section {
      padding: 160rem;
    }

    .devl-card-area {
      .card-title {
        margin: 0;
        font-size: 40rem;
        font-weight: 700;
      }

      .card-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card-item {
          background: #fff;
          width: 288rem;
          height: 294rem;
          border-radius: 32rem;
          position: relative;
          padding: 40rem 20rem 40rem 40rem;
          box-sizing: border-box;

          .num {
            display: block;
            margin: 0;
            padding: 0;
            font-family: $font-family-2;
            font-size: 24rem;
            font-weight: 500;
            line-height: 32rem;
          }

          b {
            display: block;
            margin: 48rem 0 0 0;
            padding: 0;
            font-size: 24rem;
            font-weight: 700;
            line-height: 30rem;
          }

          ul {
            margin: 20rem 0 0 0;
            padding: 0;

            li {
              position: relative;
              padding: 0 0 0 12rem;
              font-size: 20rem;
              font-weight: 300;
              line-height: 24rem;
              list-style: none;

              &+li {
                margin: 8rem 0 0 0;
              }

              &::before {
                content: '';
                position: absolute;
                width: 4rem;
                height: 4rem;
                background-color: #000;
                top: 10rem;
                left: 0;
              }
            }
          }
        }

        .card-dots-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 24rem;
          margin: 0;
          padding: 0;

          .card-dots {
            display: block;
            width: 4rem;
            height: 4rem;

            &.w-square {
              background: #FFFFFF;
            }

            &.b-diamond {
              background: #1e75ff;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .devl-type-area {
      width: 100%;

      .type-title {
        font-weight: 700;
        font-size: 40rem;
        padding: 0;
        margin: 0;
      }

      .type-imgbox-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .type-img-box {
          .img-box {
            width: 784rem;
            height: 480rem;

            img {
              display: block;
              width: 100%;
            }
          }

          .type-img-title {
            display: block;
            margin: 32rem 0 0 0;
            padding: 0;
            font-size: 32rem;
            font-weight: 700;
          }

          .type-desc {
            font-size: 20rem;
            font-weight: 300;
            padding: 0;
            margin: 20rem 0 0 0;
          }
        }
      }
    }
  }

  //상업시설 운영 section
  .ts-theme-operation {
    width: 100%;

    .inner-section {
      padding: 160rem;
    }

    .opar-ico-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 32rem;

      .box-item {
        flex-basis: calc(50% - 19rem);
        height: 369rem;
      }
    }
  }

  // 건설사업 관리 section
  .ts-theme-management {
    width: 100%;
    background: #f5f6f8;

    .inner-section {
      padding: 160rem;

      .mang-ico-area {
        .mang-ico-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 32rem;

          .box-item {
            flex-basis: calc(100% / 3 - 25rem);
            height: 325rem;
          }
        }
      }
    }
  }

  // 부동산 컨설팅 section
  .ts-theme-consulting {
    .inner-section {
      padding: 160rem;
    }

    .cons-vison-wrap {
      .cons-title {
        font-size: 40rem;
        padding: 0;
        margin: 0;
      }

      .cons-ico-box {
        display: flex;
        align-items: center;

        .box-item {
          width: calc(50% - 32rem);
          height: 365rem;

          .desc {
            font-size: 24rem;
            line-height: 32rem;
            font-weight: 500;
            text-align: center;
          }
        }

        .ico-plus {
          display: block;
          position: relative;
          margin: 0 20rem;
          width: 24rem;
          height: 24rem;

          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            width: 24rem;
            height: 6rem;
          }

          &::before {
            background-color: #1E75FF;
            transform: translateY(-50%);
          }

          &::after {
            background-color: rgba(255, 172, 46, .6);
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }

      .cons-vision-box {
        background: #1E75FF;
        color: #fff;
        padding: 80rem 0;
        text-align: center;

        .vision-title {
          margin: 0;
          padding: 0;
          font-family: $font-family-2;
          font-size: 64rem;
          font-weight: 700;
        }

        .vision-desc {
          margin: 0;
          padding: 0;
          font-family: $font-family-1;
          font-size: 32rem;
          font-weight: 700;
        }
      }
    }

    .cons-info-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 56rem;

      .cons-info-box {
        .info-title {
          margin: 0 0 25rem 0;
          padding: 0;
          font-size: 32rem;
          font-weight: 700;
        }

        .info-text {
          display: block;
          margin: 0 0 12rem 0;
          padding: 0;
          font-size: 20rem;
          font-weight: 700;
        }

        .info-desc {
          padding: 0;
          margin: 0 0 12rem 0;
          font-weight: 300;
          font-size: 20rem;
          letter-spacing: -0.32rem;
          font-family: $font-family-2;

          .desc-item {
            margin-right: 62rem;
          }
        }

        &:first-child {
          width: 470rem;
        }

        &:nth-child(2) {
          width: 623rem;
          margin-right: 56rem;
        }

        &:last-child {
          width: 395rem;
          box-sizing: border-box;
        }
      }
    }
  }

  // 탭전환 section
  .ts-theme-dev-tab {
    position: relative;

    .inner-section {
      .wrap-title {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;
        text-align: center;

        .txt-ts {
          display: block;
          color: $color-white;
          font-family: $font-family-2;
          font-size: 20rem;
          font-weight: 500;
          line-height: 24rem;
          letter-spacing: 0.1em;
        }

        .txt-title {
          margin: 12rem 0 0 0;
          color: $color-white;
          font-size: 72rem;
          font-family: $font-family-2;
          font-weight: 700;
          line-height: 86rem;
          letter-spacing: -0.016em;
        }
      }
    }
    .swiper-fade-slide {
      .swiper-slide {
        padding:182rem 0 231rem;
        box-sizing:border-box;
        height:1080rem;
        background-repeat:no-repeat;
        background-position: top center;
        background-size: cover;
      }
      .swiper-pagination {
        position: absolute;
        top: 322rem;
        bottom: auto;
        left: 160rem;
        z-index: 2;
        margin: 0;
        padding: 0;
        width: auto;
        button {
          display: block;
          margin:0;
          padding: 0;
          border-width: 0 0 1rem 0;
          border-color: transparent;
          border-radius:0;
          box-shadow: none;
          width:auto;
          height:26rem;
          color: #fff;
          font-family: $font-family-2;
          font-size: 20rem;
          font-weight: 300;
          cursor: pointer;
          background: transparent;
          opacity: 1;
          & + button {
            margin: 32rem 0 0 0;
          }
          &.swiper-pagination-bullet-active,
          &:hover,
          &:focus {
            font-weight: 700;
            border-bottom: 1rem solid #fff;
          }
        }
      }
    }

    .box-information {
      position: relative;
      z-index: 1;
      margin: 64rem auto 0;
      padding: 64rem 56rem;
      box-sizing: border-box;
      width: 596rem;
      background-color: rgba(0, 0, 0, .5);

      dl {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        color: $color-white;
        font-size: 24rem;

        dt {
          flex-shrink: 0;
          margin: 0;
          padding: 0;
          width: 148rem;
          font-family: $font-family-2;
          font-weight: 300;
        }

        dd {
          margin: 0;
          padding: 0;
          font-family: $font-family-2;
          font-weight: 500;
        }

        &+dl {
          margin: 20rem 0 0 0;
        }
      }
    }

    .tab-menu {
      position: absolute;
      top: 377rem;
      left: 160rem;
      z-index: 2;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;

        >button {
          display: block;
          margin:0;
          padding: 0;
          border-width: 0 0 1rem 0;
          border-radius: 0;
          border-color: transparent;
          box-shadow: none;
          color: #fff;
          font-family: $font-family-2;
          font-size: 20rem;
          font-weight: 300;
          cursor: pointer;
          background-color: transparent;

          &:hover,
          &:focus {
            font-weight: 700;
            border-bottom: 1rem solid #fff;
          }

        }

        &+li {
          margin: 32rem 0 0 0;
        }

        &.active {
          >button {
            font-weight: 700;
            border-bottom: 1rem solid #fff;
          }
        }
      }
    }

    .wrap-bg {
      list-style: none;

      li {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        opacity: 0;
        transition: opacity .3s;

        &.active {
          opacity: 1;
          transition: opacity .3s;
        }
        
      }
    }
  }


}