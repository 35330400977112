&subpage-title {
  &.history {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    height: 100vh;
    background-color: #263040;
    .txt-title {
      margin: 0;
      font-size: 48rem;
      color: #fff;
      letter-spacing: 0;
    }
    .txt-bg {
      --timer: 40s;
      position: absolute;
      left: 0;
      display: flex;
      font-size: 400rem;
      font-weight: 700;
      color: #f5f6f8;
      white-space: nowrap;
      opacity: 0.04;
      animation: textFlow1008 var(--timer) linear infinite;
    }
    .thumb-parallax {
      --timer: 40s;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: calc(2660rem + 100rem); //반복 간격 100rem 더함.
      height: 100%;
      animation: imageFlow1008 var(--timer) linear infinite;
      &.animate {
        .target {
          .item {
            img {
              transform: translateX(0) scale(1);
              border-radius: 32rem;
              box-shadow: 4rem 8rem 12rem rgba(0, 0, 0, .2);
            }
          }
        }
      }
      .target {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        .item {
          position: absolute;
          margin: 0;
          img {
            width: 100%;
            transform: translateX(50vw) scale(0.3);
            transition: transform 1.5s;
          }
          &.history-1919 {
            left: 0rem;
            top: 50%;
            width: 184rem;
            height: 184rem;
            transform: translateY(calc(-50% - 168rem));
          }
          &.history-1924 {
            left: 200rem;
            top: 50%;
            width: 320rem;
            height: 320rem;
            transform: translateY(calc(-50% + 216rem));
          }
          &.history-1940 {
            left: 455rem;
            top: 50%;
            width: 232rem;
            height: 232rem;
            transform: translateY(calc(-50% - 260rem));
          }
          &.history-1945 {
            left: 829rem;
            top: 50%;
            width: 184rem;
            height: 184rem;
            transform: translateY(calc(-50% + 232rem));
          }
          &.history-1962 {
            left: 1068rem;
            top: 50%;
            width: 140rem;
            height: 140rem;
            transform: translateY(calc(-50% - 188rem));
          }
          &.history-1973 {
            left: 1285rem;
            top: 50%;
            width: 232rem;
            height: 232rem;
            transform: translateY(calc(-50% + 144rem));
          }
          &.history-1990 {
            left: 1621rem;
            top: 50%;
            width: 140rem;
            height: 140rem;
            transform: translateY(calc(-50% - 109rem));
          }
          &.history-2000 {
            left: 1882rem;
            top: 50%;
            width: 184rem;
            height: 184rem;
            transform: translateY(calc(-50% + 284rem));
          }
          &.history-2010 {
            left: 1997rem;
            top: 50%;
            width: 320rem;
            height: 320rem;
            transform: translateY(calc(-50% - 77rem));
          }
          &.history-2024 {
            left: 2428rem;
            top: 50%;
            width: 232rem;
            height: 232rem;
            transform: translateY(calc(-50% - 258rem));
          }
        }
      }
    }
  }
}
&subpage-history {
  &-tab {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36rem 0 80rem;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, #fff 0%, #fff 55%, rgba(255, 255, 255, 0.5) 75%, rgba(255, 255, 255, 0) 100%);
    }

    > ul {
      display: flex;
      align-items: center;
      position: relative;
      margin: 0;
      padding: 15rem 11rem;
      border: 1rem solid #e0e0e0;
      background-color: #fff;
      border-radius: 16rem;
      z-index: 1;
      > li {
        display: inline-flex;
        margin: 0 12rem;
        > a {
          font-size: 20rem;
          line-height: 24rem;
          font-weight: 500;
          font-family: $font-family-2;
          color: inherit;
          text-decoration: none;
          &.active {
            margin: 0 -10rem;
            padding: 7rem 10rem 9rem;
            border-radius: 12rem;
            background-color: #000000;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }
  &-content {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0 0 160rem;
    margin-bottom: -160rem;
    &::before {
      content: "";
      position: absolute;
      top: -80rem;
      bottom: -80rem;
      left: 50%;
      width: 1rem;
      background-color: #e0e0e0;
    }
    .history-view {
      position: sticky;
      top: 188rem;
      // flex: 0 0 calc(50% - 150rem);
      // width: calc(50% - 152rem);
      width: calc(50% - 152rem);
      margin-right: auto;
      text-align: center;
      box-sizing: border-box;
      .tit {
        position: relative;
        margin: 0 0 48rem;
        font-size: 72rem;
        font-weight: 300;
        line-height: 87rem;
        font-family: $font-family-2;
        &::before {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: 1rem;
          height: 32rem;
          background-color: #e0e0e0;
        }
      }
      .copy {
        color: $color-p;
        font-family: $font-family-2;
        font-size: 24rem;
        font-weight: 700;
      }
      .txt {
        display: block;
        margin: 16rem 36rem 32rem;
        font-family: $font-family-2;
        font-size: 20rem;
        line-height: 30rem;
        font-weight: 300;
        word-break: keep-all;
      }
      .thumb-wrap {
        position: relative;
        .thumb {
          visibility: hidden;
          width: 100%;
          margin: 0;
          font-size: 0;
          opacity: 0;
          transition: opacity .5s, visibility .5s;
          img {
          width: 100%;
          }
          &.pos-abs {
            position: absolute;
            left: 0;
            top: 0;
          }
          &.active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .count-area {
        --textHeight: 0px; /* 텍스트 높이 JS컨트롤 */
        overflow: hidden;
        display: flex;
        justify-content: center;
        height: var(--textHeight);
        .count-number-wrap {
          overflow: hidden;
          display: flex;
          height: 100%;
          .num-wrap {
            --duration: 0.5s; /* 롤링 시간 */
            --rollingHeight: 0px;
            height: var(--rollingHeight);
            span {
              display: block;
            }
          }
        }
        .wave {
          margin: 0 16px;
        }
      }
    }
    .history-content-wrap {
      width: 50%;
      padding-left: 48rem;
      margin-top: -80rem;
      box-sizing: border-box;
      .history-list {
        padding: 0;
        margin: 80rem 0 0 0;
        // flex: 0 0 calc(50% - 48rem);
        margin-left: auto;
        list-style: none;
        // + .history-list {
        //   margin-top: -108rem;
        // }
        > li {
          + li {
            margin-top: 80rem;
          }
          .tit {
            position: relative;
            font-family: $font-family-2;
            font-size: 32rem;
            line-height: 45rem;
            font-weight: 700;
            &::before {
              content: "";
              position: absolute;
              top: 13rem;
              left: -53rem;
              width: 12rem;
              height: 12rem;
              background-color: $color-p;
              transform: rotate(45deg);
            }
          }
          .txt {
            margin: 24rem 0 0;
            font-family: $font-family-2;
            font-size: 20rem;
            line-height: 30rem;
          }
        }
      }
    }
  }
}
@keyframes textFlow1008 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--slide-width) * -1));
  }
}
@keyframes imageFlow1008 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    /* transform: translateX(calc(var(--slide-width) * -1)); */
  }
}
