/* form */
&form {
  .form-title {
    display: block;
    margin: 64rem 0 0;
    font-size: 24rem;
    font-weight: 700;
    & ~ .form-title {
      margin-top: 80rem;
    }
  }
  .form-desc {
    margin: 16rem 0;
    font-size: 20rem;
    font-weight: 300;
  }

  .label-text {
    display: block;
    margin: 32rem 0 16rem;
    font-size: 16rem;
    font-weight: 600;
  }

  .input-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    & + .input-group {
      margin-top: 12rem;
    }
    & > * {
      flex: 1;
      & + * {
        margin-left: 8rem;
      }
    }
    .txt-count {
      display: block;
      position: absolute;
      bottom: 16rem;
      right: 16rem;
      margin: 0;
      color: #999999;
      font-family: $font-family-1;
      font-size: 14rem;
      font-weight: 300;
      line-height: 16rem;
      .num {
        color: $color-p;
        font-weight:500;
      }
    }
  }
  .pw-box {
    display: flex;
    position: relative;
    & > * {
      flex: 1;
    }
    .input-text {
      padding-right: 48rem;
    }
    .btn-pwview {
      position: absolute;
      top: 50%;
      right: 16rem;
      width: 24rem;
      height: 24rem;
      border: none;
      @include bg-inline;
      background-color: transparent;
      background-image: url(../images/common/ico_eye_off.svg);
      transform: translateY(-50%);
      cursor: pointer;
    }
    :not([type="password"]) + .btn-pwview {
      background-image: url(../images/common/ico_eye.svg);
    }
  }
  .email-box {
    display:flex;
    align-items:center;
    border: 1rem solid #d9d9d9;
    box-sizing: border-box;
    width: 100%;
    .input-text {
      border-width: 0;
      border-color: transparent;
    }
    .selectstyle {
      border-width: 0;
      border-color: transparent;
    }
    &.error {
      border-color: $color-error;
    }
  }
  .input {
    &-text {
      width: 320rem;
      height: 56rem;
      padding: 18rem 15rem 17rem;
      border: 1rem solid #d9d9d9;
      background: $color-white;
      color: $color-black;
      font-size: 16rem;
      line-height: 19rem;
      font-weight: 300;
      font-family: $font-family-1;
      outline: none;
      box-sizing: border-box;
      &:focus {
        border-color: $color-black;
      }
      &.error {
        border-color: $color-error;
      }
      &:read-only {
        border-color: #d9d9d9;
        background: #f8f8f8;
      }
      &:disabled {
        border-color: #d9d9d9;
        color: #999;
      }
    }
    &-textarea {
      width: 320rem;
      height: 112rem;
      padding: 18rem 15rem 17rem;
      border: 1rem solid #d9d9d9;
      background: $color-white;
      color: $color-black;
      font-size: 16rem;
      line-height: 19rem;
      font-weight: 300;
      font-family: $font-family-1;
      outline: none;
      resize: none;
      box-sizing: border-box;
      &:focus {
        border-color: $color-black;
      }
      &.error {
        border-color: $color-error;
      }
      &:read-only {
        border-color: #d9d9d9;
        background: #f8f8f8;
      }
      &:disabled {
        border-color: #d9d9d9;
        color: #999;
      }
    }
    &-labelbox {
      display: inline-flex;
      align-items: flex-start;
      margin-right: 24rem;
      vertical-align: top;
      &-list {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          & + li {
            margin-top: 12rem;
          }
        }
      }
      input {
        flex:none;
        width: 24rem;
        height: 24rem;
        margin: 0;
        appearance: none;
        cursor: pointer;
        &[type="checkbox"] {
          border-radius: 0;
          background: url(../images/common/ico_checkbox_off.svg) no-repeat center/contain;
          &:checked {
            background-image: url(../images/common/ico_checkbox_on.svg);
            &:disabled {
              background-image: url(../images/common/ico_checkbox_on_disabled.svg);
            }
          }
          &:disabled {
            background-image: url(../images/common/ico_checkbox_off_disabled.svg);
          }
          &.error {
            background-image: url(../images/common/ico_checkbox_error.svg);
            & + .txt {
              color:$color-error;
            }
          }
        }
        &[type="radio"] {
          position: relative;
          width: 24rem;
          height: 24rem;
          border: 1rem solid #d9d9d9;
          border-radius: 50%;
          background-color: $color-white;
          &:checked {
            border-color: $color-p;
            background-color: $color-p;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 8rem;
              height: 8rem;
              border-radius: 50%;
              background-color: #fff;
              transform: translate(-50%, -50%);
            }
            &:disabled {
              border-color: #d9d9d9;
              background: #f8f8f8;
              &::before {
                background-color: #d9d9d9;
              }
            }
            
          }
          &:disabled {
            background: #f8f8f8;
          }
          &.error {
            border-color:$color-error;
            & + .txt {
              color:$color-error;
            }
          }
        }
        &:disabled + .txt {
          color: #999;
        }
      }
      .txt {
        margin-left: 8rem;
        font-size: 16rem;
        line-height: 24rem;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }
  .selectstyle {
    width: 320rem;
    height: 56rem;
    padding: 18rem 48rem 17rem 15rem;
    border: 1rem solid #d9d9d9;
    background: url(../images/common/ico_select_arrow.svg) no-repeat right 15rem center/24rem;
    color: $color-black;
    font-size: 16rem;
    line-height: 19rem;
    font-weight: 300;
    font-family: $font-family-1;
    appearance: none;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    &:focus {
      border-color: $color-black;
    }
    &.error {
      border-color: $color-error;
    }
    &:disabled {
      border-color: #d9d9d9;
      color: #999;
    }
  }

  .validate-box {
    display: flex;
    margin: 12rem 0 0 -24rem;
    .validate-check {
      display: inline-flex;
      align-items: center;
      font-size: 14rem;
      font-weight: 300;
      color: #999999;
      margin-left: 24rem;
      > .icon {
        margin-right: 4rem;
        width: 16rem;
        height: 16rem;
        background: url(../images/common/ico_validate_check.svg) no-repeat center/contain;
      }
      &.active {
        color: $color-p;
        > .icon {
          background-image: url(../images/common/ico_validate_check_on.svg);
        }
      }
    }
  }
}
