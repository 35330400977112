&map-box {
  width: 100%;
  max-width:1600rem;
  height: 640rem;
  border: 1rem solid $color-black;
  overflow: hidden;
}
&map-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 32rem;
  .infi-tit {
    margin: 0;
    font-size: 32rem;
    font-weight: 700;
  }
  .btns {
    display: flex;
    
    .map-btn {
      @include flex-center();
      margin-left: 16rem;
      padding: 7rem 19rem;
      border: 1rem solid #000;
      border-radius: 32rem;
      color: $color-black;
      text-decoration: none;
      background: #fff;
      cursor: pointer;
      .txt {
        font-size: 20rem;
        font-family: $font-family-2;
        font-weight: 400;
        letter-spacing: 0.016em;
      }
      [class*="ico-map"] {
        flex: none;
        width: 32rem;
        height: 32rem;
        margin-left: 12rem;
        background-position: center;
        background-size:32rem;
      }
    }
  }
}
