/* guide */
&guide {
  &tit {
    position: relative;
    margin: 0;
    padding: 20rem;
    border-bottom: 1rem solid $color-white;
    background: $color-black;
    color: $color-white;
    font-size: 24rem;
    font-weight: 700;
    cursor: pointer;
    &::before {
      content: "▼";
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 20rem;
      width: 16rem;
      height: 16rem;
      margin-top: -8rem;
      color: #fff;
      transition: 0.3s;
    }
    &.on::before {
      transform: rotate(180deg);
    }
  }
  &box {
    max-width: 1640rem;
    margin: 0 auto;
    padding: 40rem 20rem;
    box-sizing: border-box;
    .guidesubtit {
      margin: 120rem 0 60rem;
      font-size: 20rem;
      font-weight: 500;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
