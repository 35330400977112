/* sub layout */
&subpage- {
  &container {
    display: flex;
    border-top: 1rem solid #eaeaea;
    margin-top: 96rem;

    &.floor {
      margin-top: 202rem;
      border-top: none;
      // 임시 CMS
      .columns-max {
        width: 100%;
        .row {
          margin-right:0;
          margin-left:0;
        }
        .portlet-column {
          box-sizing:border-box;
        }
      }
      // 임시 CMS
      .portlet-body {
        .ts-theme-subpage-content {
          max-width:1414rem;
        }
      } 
    }
    // 임시 CMS
    .portlet-body {
      flex-wrap:wrap;
      .ts-theme-subpage-title {
        margin-top:0;
        width:100%;
      }
      .ts-theme-subpage-content {
        flex:none;
      }
    } 

    &.magazine {
      margin-top: 120rem;
      border-top: none;
    }

    &.history {
      margin-top: 0;
      border-top: none;
      .columns-max {
        width:100%;
        .ts-theme-subpage-title {
          margin-top:220rem;
        }
      }
      .portlet-layout {
        width:100%
      }
    }
  }

  &title {
    margin-top: 220rem;
    color: inherit;
    text-align: center;

    .txt-ts {
      position: relative;
      font-size: 20rem;
      line-height: 24rem;
      font-weight: 500;
      opacity: 0.64;
      letter-spacing: 0.1em;
      font-family: $font-family-2;
      z-index: 1;
    }

    .txt-title {
      position: relative;
      margin: 12rem 0 0 0;
      font-family: $font-family-2;
      font-size: 72rem;
      line-height: 86rem;
      font-weight: 700;
      letter-spacing: -0.016em;
      z-index: 1;
    }

    &.event {
      position: relative;
      height: 592rem;
      padding-top: 220rem;
      margin-top: 0;
      background: #ef9d2e;
      color: $color-white;
      box-sizing: border-box;

      .event-img-slide {
        position: absolute;
        right: 0;
        bottom: -100rem;
        left: 0;

        .swiper-container {
          display: flex;
          align-items: flex-end;
          padding: 0 160rem;
          height: 512rem;

          .swiper-wrapper {
            display: flex;
            align-items: flex-end;
            padding: 0;
            margin: 0;
            list-style: none;

            .swiper-slide {
              width: 32%;
              margin-right:32rem;

              &.swiper-slide-active {
                .inbox {
                  height: 512rem;

                  .txtbox {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }

              &.swiper-slide-next {
                transform: translateX(0);

                ~.swiper-slide {
                  transform: translateX(0);

                  .inbox {
                    height: 400rem;
                  }
                }
              }

              .inbox {
                display: block;
                position: relative;
                height: 320rem;
                // margin-right: 32rem;
                color: inherit;
                text-decoration: none;
                transition: 0.3s;

                &:hover {
                  .txtbox {
                    visibility: visible;
                    opacity: 1;
                  }
                }

                .imgbox {
                  width: 100%;
                  height: 100%;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    vertical-align: top;
                  }
                }

                .txtbox {
                  display:none;
                  // display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  background-color: rgba(0, 0, 0, 0.64);
                  visibility: hidden;
                  opacity: 0;
                  transition: 0.6s;

                  .tit {
                    font-size: 32rem;
                    line-height: 48rem;
                    font-weight: 700;
                  }

                  .info {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 32rem 0 0 0;
                    list-style: none;

                    >li {
                      position: relative;
                      font-size: 20rem;
                      line-height: 24rem;
                      font-weight: 500;
                      font-family: $font-family-2;

                      +li {
                        margin-left: 8rem;
                        padding-left: 9rem;

                        &::before {
                          content: "";
                          position: absolute;
                          top: 50%;
                          left: 0;
                          width: 1rem;
                          height: 12rem;
                          background-color: $color-white;
                          transform: translateY(-50%);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .swiper-button-prev {
          position: absolute;
          top: auto;
          right: 0;
          left: auto;
          bottom: 320rem;
          width: 80rem;
          height: 80rem;
          margin: 0;
          background: url(../images/common/btn_event_prev.svg) no-repeat center/contain;
        }

        .swiper-button-next {
          position: absolute;
          top: auto;
          right: 0;
          left: auto;
          bottom: 400rem;
          width: 80rem;
          height: 80rem;
          margin: 0;
          background: url(../images/common/btn_event_next.svg) no-repeat center/contain;
        }
      }

      & + .ts-theme-subpage-content {
        .ts-theme-tabbox {
          margin-top:220rem;
          // margin-top:156rem;
        }
      }
    }

    &.floor {
      position: relative;
      height: 640rem;
      padding-top: 220rem;
      margin-top: 0;
      color: $color-white;
      box-sizing: border-box;
      .bg {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 0;
      }
      .floor-img-slide {
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: -82rem;
        width: 100%;
        overflow: hidden;

        .img-item-group {
          display: flex;
          align-items: flex-end;

          .img-item {
            position: relative;
            width:512rem;
            margin-right: 32rem;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.2);
            }

            img {
              width:100%;
              vertical-align: top;
            }
          }
        }
      }
    }
    &.search {
      margin:220rem auto 0;

      .box-search {
        text-align: center;
        .integrated-search {
          position: relative;
          margin: 0 auto;
          border-width: 0 0 4rem 0;
          border-style: solid;
          border-color: $color-black;
          width: 784rem;
    
          [class^="btn"] {
            flex: none;
            position:absolute;
            top: 50%;
            z-index:6;
            border: 0;
            width: 32rem;
            height: 32rem;
            box-shadow: none;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 32rem 32rem;
            cursor: pointer;
            transform: translateY(-50%);
          }
          .btn-clear {
            display:none;
            right: 56rem;
            background-image: url(../images/common/btn_ico_del.svg)
          }
          .btn-search {
            right: 0;
            background-image: url(../images/common/ico_search.svg);
          }
        } 
        .input-search {
          margin:0;
          padding: 29rem 100rem 26rem 0;
          border: 0;
          outline: 0;
          width: 100%;
          color: $color-black;
          font-family: $font-family-1;
          font-size: 32rem;
          font-weight: 700;
    
          &::-moz-input-placeholder,
          &::-webkit-input-placeholder {
            color: $color-black;
            font-family: $font-family-1;
            font-size: 32rem;
            font-weight: 700;
          }
    
          &::placeholder {
            color: $color-black;
            font-family: $font-family-1;
            font-size: 32rem;
            font-weight: 700;
          }
    
          &::-ms-clear,
          &::-ms-reveal {
            display: none;
            appearance: none;
          }
    
          &::-webkit-search-decoration,
          &::-webkit-search-cancel-button,
          &::-webkit-search-results-button,
          &::-webkit-search-results-decoration {
            display: none;
            -webkit-appearance: none;
          }
          &::-webkit-search-cancel-button {
            display: none;
            -webkit-appearance: none;
          }
        }
    
        .txt-result {
          margin: 64rem 0 120rem;
          color: #999;
          font-size: 40rem;
          font-weight: 700;
          line-height: 47rem;
          text-align: center;
          em {
            font-style: normal;
            color: $color-p;
          }
        }
        .input-group {
          & > .input-group-item {
            &.input-group-append {
              overflow:hidden;
              margin:0;
            }
          }
        }

      }
    }
  }

  &floor-title {
    display: flex;
    align-items: flex-end;

    .title {
      margin: 0 32rem 0 0;
      font-size: 48rem;
      line-height: 58rem;
      font-family: $font-family-2;
      font-weight: 700;
      letter-spacing: -0.48rem;
    }

    .list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        margin-right: 24rem;
        font-size: 48rem;
        line-height: 58rem;
        font-family: $font-family-2;
        font-weight: 300;

        &+li {
          margin-left: 7rem;
          padding-left: 24rem;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -7rem;
            width: 6rem;
            height: 6rem;
            background: $color-black;
            transform: translateY(-50%) rotate(45deg);
          }
        }

        &.active {
          color: $color-p;
          font-weight: 500;
        }
      }
    }

    .btn-location {
      margin: 0 0 4rem auto;
      width: 40rem;
      height: 40rem;
      border-radius: 50%;
      @include bg-inline;
    }
  }

  &floor-tab {
    display: flex;
    padding: 0;
    margin: 32rem 0 0 0;
    list-style: none;

    li {
      margin-right: 12rem;

      a {
        display: flex;
        align-items: center;
        height: 43rem;
        padding: 0 19rem;
        border: 1rem solid $color-black;
        color: inherit;
        font-size: 16rem;
        font-weight: 700;
        text-decoration: none;

        .ico-heart {
          width: 24rem;
          height: 24rem;
          background: url("../images/common/ico_heart_black.svg") no-repeat center/contain;
        }

        &.active {
          background-color: $color-black;
          color: $color-white;
          .ico-heart{
            background-image:url("../images/common/ico_heart_white.svg");
          }
        }
      }
    }
  }

  &lnb {
    flex: none;
    width: 438rem;
    padding: 80rem 64rem 160rem;
    background-color: #f5f6f8;
    box-sizing: border-box;

    .my-userbox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: inherit;

      .txt-ts {
        font-size: 16rem;
        line-height: 19rem;
        font-weight: 400;
        font-family: $font-family-2;
      }

      .txt-name {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-top: 12rem;

        .name {
          flex: 1;
          color: inherit;
          font-size: 40rem;
          line-height: 48rem;
          font-weight: 700;
          letter-spacing: -0.016em;
          text-decoration: none;
          overflow: hidden;
        }

        .btn-alarm {
          display:none;
          position: relative;
          width: 32rem;
          height: 32rem;
          margin: 8rem 0 0 8rem;
          border-radius: 50%;
          background: url(../images/common/ico_alarm.svg) no-repeat center/contain;

          &.on::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            background-color: #ff2e2e;
          }
        }
      }

      // [dev] 개발 적용 후 수정 필요
      .wrap-btn-info {
        display:flex;
      }
      .btn-edit-info {
        display: inline-flex;
        margin-top: 24rem;
        padding: 7rem 15rem 8rem;
        border: 1rem solid $color-black;
        border-radius: 8rem;
        background: $color-white;
        color: $color-black;
        font-size: 16rem;
        line-height: 19rem;
        text-decoration: none;
        & + .btn-logout {
          margin-left:8rem;
        }
      }
    }

    .my-menulist {
      margin: 64rem 0 0 0;
      padding: 64rem 0 0 0;
      border-top: 1rem solid $color-black;
      list-style: none;

      >li {
        &+li {
          margin-top: 48rem;
        }

        >a {
          display: flex;
          color: inherit;
          font-size: 24rem;
          line-height: 29rem;
          font-weight: 700;
          text-decoration: none;
          letter-spacing: -0.016rem;

          &.active {
            color: $color-p;
          }
        }

        >.depth {
          margin: 24rem 0 0 0;
          padding: 0;
          list-style: none;

          >li {
            &+li {
              margin-top: 16rem;
            }

            >a {
              display: flex;
              color: inherit;
              font-size: 20rem;
              line-height: 24rem;
              font-weight: 300;
              text-decoration: none;
              letter-spacing: -0.02rem;

              &.active {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .my-bannerlink {
      display: flex;
      margin-top: 80rem;

      img {
        width: 100%;
      }
    }

    & + .ts-theme-subpage-content {
      padding:0 64rem 160rem;
    }
  }

  &lnb-floor {
    flex: none;
    width: 505rem;
    padding: 0 24rem 40rem 40rem;
    border-right: 1rem solid #eaeaea;
    box-sizing: border-box;

    .floor-menu {
      margin: -20rem 0 0 0;
      padding: 0;
      list-style: none;

      >li {
        +li {
          margin-top: 48rem;
        }

        >.inbox {
          display: flex;
          position: relative;
          color: inherit;
          text-decoration: none;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-white;
          }

          // &:hover {
          //   &::before {
          //     background-color: $color-p;
          //     opacity: 0.16;
          //   }
          // }
          &.active {
            color: $color-white;

            &::before {
              background-color: $color-p;
              opacity: 1;
            }

            .cont-flex {
              .types {
                >ul {
                  >li {
                    &::before {
                      background-color: $color-white;
                    }
                  }
                }
              }
            }
          }

          &.disabled {
            color: #c8c8c8 !important;
            cursor: auto;
            pointer-events: none;

            .cont-flex .types>ul>li::before {
              background-color: #c8c8c8;
            }
          }

          .cont-flex {
            display: flex;
            align-items: flex-start;
            position: relative;
            padding: 14rem 24rem;

            .tit {
              flex: none;
              position: relative;
              width: 32rem;
              padding-top: 2rem;
              font-size: 24rem;
              line-height: 32rem;
              font-weight: 700;
              font-family: $font-family-2;
            }

            .types {
              flex: 1;
              margin-left: 40rem;
              overflow: hidden;

              >ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: 0 0 0 -33rem;
                list-style: none;

                >li {
                  position: relative;
                  margin: 6rem 0 6rem 16rem;
                  padding-left: 17rem;
                  font-size: 20rem;
                  line-height: 24rem;
                  font-weight: 500;

                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1rem;
                    height: 12rem;
                    background-color: $color-black;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }

    & + .ts-theme-subpage-content {
      padding: 0 64rem 160rem;
    }
  }

  &lnb-magazine {
    flex: none;
    position: relative;
    width: 768rem;
    margin: 0;
    background: #8022ff;
    box-sizing: border-box;

    &+[class*="-subpage-content"] {
      background: #f5f6f8;
    }

    .inbox {
      display: block;
      padding: 120rem 64rem 64rem;
      color: inherit;
      text-decoration: none;

      .date {
        font-size: 24rem;
        color: #fff;
        font-family: $font-family-2;
        font-weight: 700;
        margin: 0 0 16rem 0;
      }

      .tit {
        color: #fff;
        font-family: $font-family-2;
        font-size: 48rem;
        font-weight: 700;
        margin: 0;
      }

      .imgbox {
        overflow: hidden;
        margin-top: 63rem;
        width: 640rem;
        max-height:824rem;
        img {
          width: 100%;
          vertical-align: top;
        }
      }

    }

    .magazine-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;

      button {
        border: none;
        background: inherit;

        .ico {
          width: 12rem;
          height: 12rem;
          background: #000;
          // padding: 34rem;
          margin: 34rem;
        }
      }

      .btn-next {
        margin: 0;
        padding: 0;
        background: #fff;
      }

      .btn-perv {
        margin: 0;
        padding: 0;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &content {
    flex: 1;
    width: 100%;
    max-width: 1600rem;
    min-width: 0;
    margin: 0 auto;
    padding: 0 0 160rem;
    box-sizing:border-box;
    .ts-theme-subpage-content {
      max-width:none;
      padding:0;
      margin:0;
    }
    &.pdno {
      padding: 0 0 160rem;
    }
  }

  &con-title {
    display: flex;
    align-items: flex-end;
    margin-top: 120rem;

    * {
      text-decoration: none;
    }

    .title {
      margin: 0 12rem 0 0;
      font-size: 40rem;
      line-height: 48rem;
      font-weight: 700;
    }

    .cnt {
      margin: 4rem 0;
      padding: 4rem 11rem;
      border: 1rem solid $color-p;
      border-radius: 16rem;
      box-sizing:border-box;
      min-width: 40rem;
      color: $color-p;
      font-size: 16rem;
      font-weight: 700;
      line-height: 19rem;
      text-align:center;
      background: #e2edff;
    }

    .view-all {
      position: relative;
      padding-right: 25rem;
      margin-left: auto;
      color: #000;
      font-size: 14rem;
      line-height: 16rem;
      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -5rem;
        width: 80rem;
        height: 12rem;
        background: url(../images/common/ico_arrow_black.svg) no-repeat center/contain;
      }
    }
  }
}

/***** 서브 공통 *****/
&dl-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 24rem 0;
  border-top: 1rem solid #eaeaea;
  border-bottom: 1rem solid #eaeaea;
  font-size: 16rem;
  line-height: 24rem;

  dt {
    flex: none;
    width: 96rem;
    margin: 12rem 0 0 0;
    font-weight: 300;
    text-align: left;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  dd {
    width: calc(100% - 96rem);
    margin: 12rem 0 0 0;
    padding-left: 12rem;
    text-align: right;
    font-weight: 600;
    box-sizing: border-box;

    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

/* 안내글 */
&msgbox {
  padding: 32rem;
  background-color: #f5f6f8;

  .msg-info {
    display: flex;
    align-items: center;
    font-size: 20rem;
    line-height: 24rem;
    font-weight: 600;
    color: $color-black;

    .icon {
      display: inline-block;
      margin-right: 8rem;
      width: 24rem;
      height: 24rem;
      background: url(../images/common/ico_info.svg) no-repeat center/contain;
      border-radius: 50%;
    }

    &+.list-desc {
      margin-top: 20rem;
    }
  }

  .list-desc {
    margin: 0;
    padding: 0;

    >li {
      position: relative;
      padding-left: 12rem;
      color: $color-black;
      font-size: 16rem;
      line-height: 19rem;
      list-style: none;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 8rem;
        left: 0;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: $color-black;
      }
    }

    >li+li {
      margin-top: 12rem;
    }
  }

  &.noBg {
    padding: 0;
    background-color: transparent;
  }
  
  &.line {
    border:1rem solid #EAEAEA;
    background-color: transparent;
  }
}

/* 쿠폰 */
&coupon-item {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  width: 430rem;
  min-height: 276rem;
  border-radius: 16rem;

  &::before {
    content: "";
    position: absolute;
    left: -12rem;
    bottom: 51rem;
    width: 24rem;
    height: 28rem;
    background: url(../images/common/bg_coupon_round_left.png) no-repeat center / contain;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    right: -12rem;
    bottom: 51rem;
    width: 24rem;
    height: 28rem;
    background: url(../images/common/bg_coupon_round_right.png) no-repeat center / contain;
    z-index: 1;
  }

  .titbox {
    position: relative;
    flex-grow: 1;
    padding: 32rem 32rem 24rem;
    border: 1rem solid #d9d9d9;
    border-bottom: none;
    border-radius: 16rem 16rem 0 0;
    background-color: #fff;

    .badge {
      display: inline-block;
      margin-bottom: 16rem;
      padding: 0rem 12rem;
      border-radius: 16rem;
      color: #fff;
      font-size: 14rem;
      font-weight: 700;
      line-height: 28rem;
      background-color: #000000;
    }

    .tit {
      display: block;
      font-size: 24rem;
      font-weight: 700;
      line-height: 32rem;
    }

    .desc {
      display: block;
      margin-top: 8rem;
      color: #666666;
      font-size: 20rem;
      font-weight: 300;
      line-height: 24rem;
    }

    .date {
      display: block;
      margin-top: 24rem;
      color: #999999;
      font-family: $font-family-2;
      font-size: 14rem;
      font-weight: 500;
      line-height: 24rem;
    }
  }

  .downloadbox {
    height: 63rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f6f8;
    text-decoration: none;
    border: 1rem solid #d9d9d9;
    border-top: none;
    border-radius: 0 0 16rem 16rem;

    .copy {
      display: inline-block;
      padding-right: 28rem;
      font-size: 16rem;
      color: #000;
      font-weight: 700;
      line-height: 24rem;
    }
  }

  >.done {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.64);
    z-index: 9;

    .icon-done {
      display: inline-block;
      width: 116rem;
      height: 115rem;
      background: url(../images/common/bg_coupon_done.svg) no-repeat center/contain;

      >em {
        padding-top: 61rem;
        display: block;
        text-align: center;
        font-style: normal;
        font-size: 16rem;
        color: #fff;
      }
    }
  }

  .btn-tooltip {
    position: absolute;
    z-index: 999;
    right: 136rem;
    bottom: 13rem;
    display: block;
    border: 0;
    padding: 0;
    outline: 0;
    width: 40rem;
    height: 40rem;
    background: url(../images/common/ico_tooltip.svg) no-repeat center;
    background-size:20rem;
    border-radius: 50%;
    cursor: pointer;

    &.on {
      background-image: url(../images/common/ico_tooltip_on.svg);
    }
  }
}

&box-app-layer {
  position: relative;

  .app-layer {
    display: none;
    position: absolute;
    bottom: 55rem;
    left: 50%;
    width: 296rem;
    transform: translateX(-50%);

    img {
      width: 100%;
      vertical-align: top;
    }
  }
}

/* 쿠폰 슬라이드 */
&coupon-swiper {
  margin-top: -32rem;
  padding-top: 64rem;
  overflow: hidden;

  .swiper-container {
    margin-right: -32rem;
    padding-bottom: 52rem;
    overflow: visible;

    .swiper-wrapper {
      padding: 0;
      margin: 0;
      list-style: none;

      .swiper-slide {
        .ts-theme-box-app-layer {
          width: calc(100% - 32rem);
          margin-right: 32rem;

          .ts-theme-coupon-item {
            width: 100%;
          }
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      top: auto;
      right: 32rem;
      bottom: 0;
      left: 0;
      width: auto;
      background: rgba(0, 0, 0, 0.08);
    }

    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-fill {
        background: $color-black;
      }
    }
  }
}

/* 이벤트 슬라이드 */
&event-swiper {
  margin-top: 32rem;
  overflow: hidden;

  .swiper-container {
    margin-right: -32rem;
    padding-bottom: 52rem;

    .swiper-wrapper {
      padding: 0;
      margin: 0;
      list-style: none;

      .swiper-slide {
        >.inbox {
          overflow:hidden;
          display: flex;
          flex-direction: column;
          text-decoration: none;
          margin-right: 32rem;
          
          .imgbox {
            overflow: hidden;
            display: flex;
            position: relative;

            img {
              width: 100%;
              transform: scale(1);
              transition: transform .3s;
            }

            .tag {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 6rem 12rem;
              background-color: $color-black;
              color: $color-white;
              font-size: 14rem;
              line-height: 17rem;
              font-weight: 700;
            }

            .txt-ended {
              @include flex-center;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.64);
              color: $color-white;
              font-size: 20rem;
              line-height: 24rem;
              font-weight: 500;
              text-align: center;
              z-index: 1;
            }
          }

          .txtbox {
            .tit {
              display: block;
              margin: 24rem 0 0 0;
              color: #111;
              font-size: 24rem;
              line-height: 29rem;
              font-weight: 700;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .date {
              display: block;
              margin-top: 16rem;
              color: #999;
              font-size: 16rem;
              line-height: 26rem;
              font-weight: 500;
              letter-spacing: -0.0165rem;
              font-family: $font-family-2;
            }
          }
          
          &:hover,
          &:focus {
            .imgbox {
              img {
                transform: scale(1.2);
                transition: transform .3s;
              }
            }
          }
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      top: auto;
      right: 32rem;
      bottom: 0;
      left: 0;
      width: auto;
      background: rgba(0, 0, 0, 0.08);
    }

    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-fill {
        background: $color-black;
      }
    }
  }
}

/* 개인정보처리방침 */
&policy {
  font-family: $font-family-1;
  font-size: 20rem;

  .ts-theme-form {
    margin: 120rem 0 0 0;
    text-align: right;

    .selectstyle {
      padding: 18rem 48rem 17rem 0;
      border-width: 0 0 2rem 0;
      border-color: $color-black;
      color: $color-black;
      font-family: $font-family-1;
      font-weight: 500;
      background-position: right center;
    }

    &+.ts-theme-form-contents {
      margin: 64rem 0 0 0;
    }
  }

  .item {
    &+.item {
      margin: 80rem 0 0 0;
    }
  }

  h3 {
    margin:0;
    padding:0;
    font-size:32rem;
    font-weight:700;
    & + ol {
      margin-top:24rem;
    }
  }

  ul {
    margin:0;
    padding:0;
    li {
      list-style: none;
      font-size: 20rem;
    }
    & + h3 {
      margin-top:80rem;
    }
  }

  ol {
    margin:0;
    padding:0;
    li {
      list-style: none;
      font-size: 20rem;
      & + li {
        margin-top: 16rem;
      }
    }
    & > li {
      & > ol {
        margin-top:12rem;
        padding-left:22rem;
        & > li {
          & + li {
            margin-top:8rem;
          }
        }
      }
  
      & > ul {
        margin-top:12rem;
        padding-left:22rem;
        & > li {
          & + li {
            margin-top:8rem;
          }
        }
      }
    }

    & + p {
      margin-top:16rem;
    }

    & + h3 {
      margin-top:80rem;
    }
  }

  p {
    margin:0;
    padding:0;
    font-size:20rem;
    & + p {
      margin-top:8rem;
    }
    & + ul {
      margin-top:8rem;
    }
    & + ol {
      margin-top:8rem;
    }
    &+ table {
      margin-top:12rem;
    }
  }

  table {
    border-style:solid;
    border-color:#000 transparent #ddd transparent;
    border-width:1rem 0 1rem 0;
    border-collapse:collapse;
    border-spacing:0;
    width:100%;
    thead {
      tr {
        th {
          text-align: center; 
          padding: 23rem 24rem 22rem; 
          font-size: 16rem; 
          font-weight: 700; 
          line-height:19rem; 
          background-color: #f5f6f8; 
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 24rem; 
          border-bottom:1rem solid #d9d9d9; 
          vertical-align: top;
        }
      }
    }
  }

  a {
    color:#000;
    text-decoration: none;
  }

  .list-dash {
    li {
      position: relative;
      padding: 0 0 0 32rem;

      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 20rem;
      }

      &+li {
        margin-top: 8rem;
      }
    }
  }
}

/* 대관문의, 입점문의, 팝업문의 */
&rent-information {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .box-text {
    width: 320rem;

    .title {
      margin: 0;
      padding: 0;
      color: $color-black;
      font-size: 32rem;
      line-height: 40rem;
    }

    .text {
      margin: 24rem 0 0 0;
      padding: 0;
      color: $color-black;
      font-size: 20rem;
      line-height: 30rem;
    }
  }

  .list-process {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 32rem;
    width: 1192rem;
    list-style: none;

    .item {
      border: 1rem solid #eaeaea;
      margin: 0;
      padding: 32rem;
      box-sizing: border-box;
      color: $color-black;

      .num {
        display: block;
        color: $color-black;
        font-family: $font-family-2;
        font-size: 24rem;
        font-weight: 700;
        line-height: 1;
      }

      .title {
        margin: 32rem 0 0 0;
        padding: 0;
        font-size: 20rem;
        font-weight: 600;
        line-height: 30rem;
      }

      p {
        margin: 16rem 0 0 0;
        padding: 0;
        font-size: 20rem;
        line-height: 30rem;
      }

      .box-detail {
        p {
          display: flex;
          align-items: center;
          line-height: 1;

          .chip {
            display: inline-block;
            margin: 0 8rem 0 0;
            border: 1rem solid $color-black;
            border-radius: 20rem;
            vertical-align: middle;
            width: 40rem;
            font-family: $font-family-2;
            font-size: 14rem;
            font-weight: 500;
            line-height: 28rem;
            text-align: center;
          }

          .discount {
            margin: 0 4rem;
            font-family: $font-family-2;
            font-weight: 500;
            line-height: 24rem;
          }

          &+p {
            margin-top: 8rem;
          }
        }

        .information {
          display: block;
          margin: 16rem 0 0 0;
          color: #666666;
          font-size: 16rem;
        }
      }

      [class^="box"] {
        margin: 24rem 0 0 0;

        a {
          display: block;
          color: $color-black;
          text-decoration: none;

          &+a {
            margin-top: 8rem;
          }
        }

        i {
          font-style: normal;
        }

        .btn-download {
          display: inline-block;
          padding: 7rem 47rem 7rem 15rem;
          border: 1rem solid $color-black;
          border-radius: 8rem;
          font-size: 16rem;
          line-height: 1;
          background: url(../images/common/ico_small_download.svg) no-repeat right 15rem center;
          background-size: 16rem 16rem;
        }

        .btn-round {
          display: inline-block;
          padding: 7rem 15rem 7rem 15rem;
          border: 1rem solid $color-black;
          border-radius: 8rem;
          font-size: 16rem;
          line-height: 1;
        }

        .btn-tel {
          padding: 0 0 0 32rem;
          font-family: $font-family-2;
          font-size: 20rem;
          line-height: 24rem;
          background: url(../images/common/ico_small_tele.svg) no-repeat left center;
          background-size: 24rem 24rem;
        }

        .btn-email {
          padding: 0 0 0 32rem;
          font-family: $font-family-2;
          font-size: 20rem;
          line-height: 24rem;
          background: url(../images/common/ico_small_email.svg) no-repeat left center;
          background-size: 24rem 24rem;

          &+.btn-email {
            margin: 8rem 0 0 0;
          }
        }
      }

      .list-dot {
        margin: 16rem 0 0 0;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          padding: 0 0 0 12rem;
          font-size: 20rem;
          line-height: 30rem;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 11rem;
            left: 0;
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            background-color: $color-black;
          }

          &+li {
            margin: 8rem 0 0 0;
          }
        }
      }

      &.inquiry {
        display: flex;
        padding: 33rem 31rem;
      }

      .contact {
        display: flex;
        align-items: flex-start;
        width: 50%;
        line-height: 56rem;

        [class^="ico"] {
          display: inline-block;
          position: relative;
          padding: 0 0 0 80rem;
          font-size: 20rem;
          font-style: normal;
          font-weight: 600;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            border-radius: 50%;
            width: 56rem;
            height: 56rem;
            background-color: #F5F6F8;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 40rem 40rem;
            transform: translateY(-50%);
          }
        }

        .ico-email {
          &:before {
            background-image: url(../images/common/ico_big_email.svg);
          }
        }

        .ico-phone {
          &:before {
            background-image: url(../images/common/ico_big_tele.svg);
          }
        }
        .tit {
          display:flex;
          align-items:center;

          [class^="ico"] {
            display:none;
            padding:0;
            &:before {
              display:none;
            }
            display: block;
            border-radius: 50%;
            width: 56rem;
            height: 56rem;
            background-color: #F5F6F8;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 40rem 40rem;
          }

          span {
            display: inline-block;
            position: relative;
            padding: 0 0 0 24rem;
            font-size: 20rem;
            font-style: normal;
            font-weight: 600;
          }
        }


        .detail {
          align-self: center;
          margin: 0 0 0 32rem;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 20rem;
          text-decoration: none;
          line-height: 24rem;
        }

        dl {
          margin: 17rem 0 0 32rem;
          padding: 0;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 20rem;
          text-decoration: none;
          line-height: 1;

          dt,
          dd {
            margin: 0;
            padding: 0;
          }

          .detail {
            margin: 0;
          }

          dd {
            margin-top: 12rem;

            &+dt {
              margin-top: 20rem;
            }
          }
        }
      }
      &.aos-init:nth-child(3n + 2) {
        transition-delay: 0.3s;
      }
      &.aos-init:nth-child(3n + 3) {
        transition-delay: 0.6s;
      }
    }

    &.col3 {
      .item {
        flex: 0 0 calc(33.33% - 21.9rem);

        &.h356 {
          height: 356rem;
        }

        &.h280 {
          height: 280rem;
        }

        &.h294 {
          height: 294rem;
        }
      }
    }

    &.col2 {
      .item {
        flex: 0 0 calc(50% - 16rem);
      }
    }

    &.col1 {
      .item {
        flex: 1 1 auto;
      }
    }
  }

  &+.ts-theme-rent-information {
    margin: 160rem 0 0 0;
  }
}

/* 1:1 문의하기 상단 박스 */
&inquiry-personal {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 32rem 48rem 32rem 40rem;
  box-sizing: border-box;
  background-color: #F5F6F8;

  .item {
    display: flex;
    align-items: flex-start;
    
    [class^="ico"] {
      flex-shrink: 0;
      align-self: center;
      display: inline-block;
      position: relative;
      padding: 0 0 0 80rem;
      vertical-align: middle;
      font-size: 20rem;
      font-style: normal;
      font-weight: 600;
      line-height: 56rem;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        border-radius: 50%;
        width: 56rem;
        height: 56rem;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40rem 40rem;
        transform: translateY(-50%);
      }
    }
    .ico-user {
      &:before {
        background-image:url(../images/common/ico_big_user.svg);
      }
    }
    .ico-email {
      &:before {
        background-image:url(../images/common/ico_big_email.svg);
      }
    }
    .ico-phone {
      &:before {
        background-image:url(../images/common/ico_big_phone.svg);
        background-size: 34rem 34rem;
      }
    }
    .text {
      margin: 0 0 0 32rem;
      padding:11rem 0;
      font-family: $font-family-2;
      font-size: 20rem;
      font-weight: 300;
    }
    &:nth-child(1) {
      width: 320rem;
      margin-right:20rem;

    }
    &:nth-child(2) {
      width: 510rem;
      margin-right: 20rem;
    }
    &:nth-child(3) {
      width: 380rem;
    }
  }
  & + .ts-theme-form {
    .ts-theme-bo-table {
      tbody {
        tr {
          th {
            padding:48rem 22rem 48rem 24rem;
          }
        }
      }
    }
  }
}

&integrated-search {
  .title {
    margin:0;
    padding:0;
    color: $color-black;
    font-size: 32rem;
    font-weight: 700;
  }
  .ts-theme-bo-no-content {
    margin-bottom:-1rem;
    border-top:1rem solid $color-black;
    border-bottom: 1rem solid #eaeaea;    
  }
}

/* 매거진 목록 */
&scrollwrap {
  display: flex;
  padding:0 64rem;
  box-sizing:border-box;
  
  ul {
    flex: 1;
    padding: 0;
    margin: 0;
    & + ul {
      margin-left: 64rem;
    }
    &.left-box {
      margin-top: 120rem;
      transition: .3s linear;
    }
    &.right-box {
      margin-top: 240rem;
      transition: .3s linear;
    }
    li {
      list-style: none;
      + li {
        margin-top: 96rem;
      }
      .inbox {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        width:480rem;
        .imgbox {
          overflow:hidden;
          display: flex;
          max-height: 640rem;
          img {
            width: 100%;
            vertical-align: top;
            transform: scale(1);
            transition: transform .3s;
          }
        }
        .txtbox {
          .tit {
            display: block;
            margin: 24rem 0 0 0;
            color: #111;
            font-family: $font-family-2;
            font-size: 24rem;
            line-height: 29rem;
            font-weight: 700;
          }
          .date {
            display: block;
            margin: 16rem 0 0 0;
            color: #999;
            font-size: 16rem;
            line-height: 26rem;
            font-weight: 500;
            letter-spacing: -0.0165rem;
            font-family: $font-family-2;
          }
        }
        &:hover,
        &:focus {
          .imgbox {
            img {
              transform: scale(1.2);
              transition: transform .3s;
            }
          }
        }
      }
    }
  }
}

/* 인트로 동영상 */
&splash {
  overflow:hidden;
  position:relative;
  height:100vh;
  .ts-theme-video {
    display:block;
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    object-fit: cover;
  }
}

/* 서브 개별디자인 */
// 경방 100년사
@import "./TS_UX_1008";
// 타임스퀘어 소개
@import "./TS_UX_3016";
// 편의시설
@import "./TS_UX_3017";
// 주차안내
@import "./TS_UX_3018";
// 오시는 길
@import "./TS_UX_3019";
// 사업영역
@import "./TS_UX_3034";